import useAudioContext from "../../audio/useAudioContext";
import { divider, white } from "../../static/styles/colorConstants";
import Box from "../../styled-components/Box";
import { ReactComponent as PhoneSlashIcon } from '../../assets/svg/phone-slash.svg';
import { ReactComponent as MicrophoneSlashIcon } from '../../assets/svg/microphone-slash.svg';
import { ReactComponent as MicrophoneIcon } from '../../assets/svg/microphone.svg';
import { IconButton } from "../../styled-components/IconButton";
import { gql } from "@apollo/client";
import { useLeaveKlatchMutation } from "./__generated__/NetworkingSection.gql.generated";
import { useGetUpdatedKlatchDetailsLazyQuery } from "./__generated__/NetworkingSection.gql.generated";
import { useAtom } from "jotai";
import { klatchAtom } from "../../app/atoms";
import KlatchParticipant from "../KlatchParticipant/KlatchParticipant";
import { useEffect, useState } from "react";
import { User } from "../../types/production.graphql";
import LoadingDots from "../../styled-components/Loading";
import { GetUpdatedKlatchDetails_POLL_INTERVAL } from "../../app/pollIntervals";

gql`
  query GetUpdatedKlatchDetails($klatchId: ID!) {
    klatch(id: $klatchId) {
      ...KlatchDetailsFragment_klatch
    }
  }
`;

gql`
  mutation LeaveKlatch($input: KlatchLeaveInput!) {
    leaveKlatch(leaveInput: $input)
  }
`;

export default function NetworkingSection() {
  const { klatchRoom, localAudioTrack } = useAudioContext();
  const [activeKlatch, setActiveKlatch] = useAtom(klatchAtom);
  const [localAudioTrackEnabled, setLocalAudioTrackEnabled] = useState(false);

  const [leaveKlatch] = useLeaveKlatchMutation({
    refetchQueries: ["GetCommunityTopics", "HomePage"]
  });
  const [getUpdatedKlatchDetails, { data: klatchDetailsData }] = useGetUpdatedKlatchDetailsLazyQuery({
    pollInterval: GetUpdatedKlatchDetails_POLL_INTERVAL,
  });
  const connectedUsers = klatchDetailsData?.klatch?.connectedUsers || activeKlatch?.connectedUsers;

  const enableAudioTrack = () => {
    setLocalAudioTrackEnabled(true);
  };
  const disableAudioTrack = () => {
    setLocalAudioTrackEnabled(false);
  };

  const leaveKlatchOnUnload = () => {
    leaveKlatch({
      variables: {
        input: {
          id: activeKlatch?.id || "",
          topicId: activeKlatch?.topicId || "",
        }
      }
    });
  }

  const micButtonClicked = () => {
    if (!localAudioTrack) return;

    if (!localAudioTrackEnabled) {
      localAudioTrack.enable();
    } else {
      localAudioTrack.disable();
    }
  }

  useEffect(() => {
    if (localAudioTrack) {
      localAudioTrack.on('enabled', enableAudioTrack);
      localAudioTrack.on('disabled', disableAudioTrack);
      setLocalAudioTrackEnabled(localAudioTrack.isEnabled);
    }

    return () => {
      localAudioTrack?.off('enabled', enableAudioTrack);
      localAudioTrack?.off('disabled', disableAudioTrack);
    };
  }, [localAudioTrack]);

  useEffect(() => {
    window.addEventListener('beforeunload', leaveKlatchOnUnload);
    return () => {
      window.removeEventListener('beforeunload', leaveKlatchOnUnload);
    }
  }, []);

  useEffect(() => {
    if (!klatchRoom || !activeKlatch) return;
    getUpdatedKlatchDetails({
      variables: {
        klatchId: activeKlatch.id
      }
    });
  }, [klatchRoom, activeKlatch]);

  if (!activeKlatch) {
    return <Box css={{
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}><LoadingDots/></Box>
  }

  return (
    <Box css={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }}>
      <Box css={{
        margin: "8px",
        padding: "12px",
        flex: 1,
        borderRadius: "12px",
        background: white
      }}>
        <Box css={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
          {
            (connectedUsers || []).map((user) => (
              <KlatchParticipant key={`${user.id}_${activeKlatch.id}`} user={user as User} audioConnected/>
            ))
          }
        </Box>
        <Box css={{
          borderTop: `1px solid ${divider}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "4px",
          paddingTop: "10px"
        }}>
          <Box css={{
            display: "flex",
            alignItems: "center"
          }}>
            {/* TODO <IconButton style="square"><GearIcon /></IconButton> */}
          </Box>
          <Box css={{
            display: "flex",
            alignItems: "center"
          }}>
            <IconButton style="square" onClick={() => micButtonClicked()} >
              {localAudioTrackEnabled ?
              <MicrophoneIcon/> :
              <MicrophoneSlashIcon />}
            </IconButton>
            <IconButton style="square" color="critial"
              onClick={() => {
                klatchRoom?.disconnect();
                if (!activeKlatch) return;
                leaveKlatch({
                  variables: {
                    input: {
                      id: activeKlatch.id,
                      topicId: activeKlatch.topicId,
                    }
                  }
                });
                setActiveKlatch(undefined);
              }}>
            <PhoneSlashIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
