import { styled } from "@stitches/react";
import { background, body, brand, brandHover, divider, subduedHover, white } from "../static/styles/colorConstants";

const Button = styled('button', {
  unset: 'all !important',
  display: "flex",
  padding: "7px 15px",
  width: "fit-content",
  fontWeight: 600,
  borderRadius: "8px",
  alignItems: "center",
  userSelect: "none",
  justifyContent: "center",
  cursor: "pointer",
  transition: "background-color 0.1s ease",
  '&:focus': { outline: 'none' },
  '& svg': {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginRight: '5px',
  },
  '& + button': {
    marginLeft: '10px',
  },
  variants: {
    style: {
      primary: {
        backgroundColor: brand,
        color: white,
        border: 0,
        '&:hover': {
          backgroundColor: brandHover,
        },
        '& svg': {
          fill: white
        },
      },
      subdued: {
        backgroundColor: white,
        border: `1px solid ${divider}`,
        color: body,
        '& svg': {
          fill: body
        },
        '&:hover': {
          backgroundColor: subduedHover,
        },
      },
      outlined: {
        backgroundColor: white,
        color: brand,
        border: `1px solid ${divider}`,
        '& svg': {
          fill: brand
        },
        '&:hover': {
          backgroundColor: subduedHover,
        },
      },
    },
    disabled: {
      true: {
        pointerEvents: "none",
        cursor: "not-allowed",
        background: background,
        color: divider,
      }
    },
    fullWidth: {
      true: {
        width: '95%',
        margin: 'auto'
      },
    },
    size: {
      small: {
        fontSize: '13px',
        height: '30px',
        padding: "5px 10px",
        minWidth: "auto",
        '& svg': {
          height: '12px'
        },
      },
      large: {
        fontSize: '15px',
        height: '40px',
        '& svg': {
          height: '13px',
        },
      },
    },
  },
  defaultVariants: {
    style: "primary",
    size: "large",
  }
});

export default Button;
