import { gql } from '@apollo/client';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { communityAtom, homeAtom, klatchAtom } from '../../app/atoms';
import useAuthContext from '../../auth/useAuthContext';
import Header from '../../components/Header/Header';
import NavigationSidebar from '../../components/NavigationSidebar/NavigationSidebar';
import NetworkingSection from '../../components/NetworkingSection/NetworkingSection.gql';
import TopicsContent from '../../components/TopicsContent/TopicsContent.gql';
import { background } from '../../static/styles/colorConstants';
import Box from '../../styled-components/Box';
import LoadingDots from '../../styled-components/Loading';
import { User } from '../../types/production.graphql';
import { useHomePageQuery } from './__generated__/HomePage.gql.generated';

const HEADER_HEIGHT = 64;

gql`
  query HomePage {
    viewer {
      ...UserProfileFragment_user
    }
    popularPublicTopics {
      ...TopicSectionFragment_topic
    }
    publicCommunities {
      ...CommunitySelectFragment_community
    }
  }
`

export default function HomePage() {
  const history = useHistory();
  const [community, setCommunity] = useAtom(communityAtom);
  const [isHome,setIsHome] = useAtom(homeAtom);
  const { fbUser } = useAuthContext();
  const [activeKlatch] = useAtom(klatchAtom);

  const { loading, error, data } = useHomePageQuery();

  if (!fbUser) history.push({
    pathname: '/login',
    hash: window.location.hash,
    search: window.location.search,
  });

  const queryString = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (data?.viewer && !data.viewer.username) {
      history.push({
        pathname: '/register',
        hash: window.location.hash,
        search: window.location.search,
      })
    }
  }, [data?.viewer?.username]);

  useEffect(() => {
    if (!data?.publicCommunities) return;
    const communityId = queryString.get('communityId');
    if (communityId) {
      setCommunity((data?.publicCommunities.find((c) => c.id === communityId)));
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [data?.publicCommunities]);

  if (loading) {
    return <Box css={{
      display: "flex",
      height: "100vw",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
    }}><LoadingDots/></Box>
  }

  return (
    <Box css={{
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      width: "100vw"
    }}>
      <Box css={{
        boxShadow: "0px 0px 2px 1px #EEEFF3",
        width: 248,
        padding: "16px"
      }}>
        <NavigationSidebar />
      </Box>
      <Box css={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flex: 1
      }}>
        <Box css={{
          width: "100%",
          height: HEADER_HEIGHT,
          boxShadow: '6px 2px 6px 0px #E6E8F0',
          zIndex: 2,
        }}>
          <Header user={data?.viewer as User} />
        </Box>
        <Box css={{
          width: "100%",
          height: `calc(100% - ${HEADER_HEIGHT}px)`,
          flex: 1,
          background: background,
          display: 'flex',
          flexDirection: 'row'
        }}>
          <Box css={{
            flex: 1,
            height: '100%'
          }}>
            <TopicsContent/>
          </Box>
          {
            activeKlatch &&
            <Box css={{
              width: 300,
              height: '100%'
            }}>
              <NetworkingSection/>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};
