import { styled } from "@stitches/react";
import { body, errorRed } from "../static/styles/colorConstants";
import Box from "./Box";

const Label = styled('label', {
  fontSize: 15,
  lineHeight: "35px",
  color: body,
  width: 110,
  justifyContent: 'flex-end',
  display: "flex",
});


const LabelContent = ({children, ...props }: any) => (
  <Label {...props}>
    {children}
    <Box css={{ color: errorRed, visibility: props["aria-required"] ? "initial": "hidden"}}> *</Box>
  </Label>
);


export { LabelContent as Label };
