import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import Box from '../../styled-components/Box';
import './SearchBar.scss';
import SearchResults from './SearchResults/SearchResults.gql';


const SearchBar = () => {
  const [text, setText] = useState('');
  const [showResultsOverlay, setShowResultsOverlay] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setShowResultsOverlay(false)}>
      <Box>
        <div className="search-input">
          <input
            className={classNames("input", { "focused-input": showResultsOverlay })}
            placeholder="Search"
            value={text}
            onChange={(e: any) => {
              setText(e.target.value);
            }}
            onFocus={(e) => setShowResultsOverlay(true)}
            type="search"
          />
          <SearchIcon className="search-icon" />
        </div>
        {
          showResultsOverlay &&
          <div className='results-overlay'>
            <SearchResults
              query={text}
              onClose={() => setShowResultsOverlay(false)}
              resetQuery={() => setText('')}/>
          </div>
        }
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBar;
