import { UserProfileFragment_UserFragment } from '../../shared-fragments/__generated__/UserProfile.gql.generated';
import Button from '../../styled-components/Button';
import SearchBar from '../SearchBar/SearchBar';
import { ReactComponent as FeedbackIcon } from '../../assets/svg/comment-alt-lines.svg'

import './Header.scss';
import UserPill from './UserPill';

type HeaderProps = {
  user?: UserProfileFragment_UserFragment
};

export default function Header({ user }: HeaderProps) {

  return (
    <div className="header-contents">
      <div className="search-bar">
        <SearchBar />
      </div>
      <Button as="a" href="https://forms.gle/Eh1m1zcrMtfbNjLA9" target="_blank" style="subdued" size="small" css={{ marginLeft: 'auto', height: "16px" }}><FeedbackIcon /> Feedback Form</Button>
      <Button as="a" href="https://calendly.com/liamnguyen/30min" target="_blank" style="subdued" size="small" css={{ marginLeft: '10px', height: "16px" }}><FeedbackIcon /> Feedback Call</Button>
      <UserPill user={user} />
    </div>
  )
};
