import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
export type KlatchDetailsFragment_KlatchFragment = { __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, connectedUsers: Array<{ __typename?: 'User', id: string, bio: string | null, fullName: string | null, username: string | null, photoUrl: string | null, icebreaker: string | null }> | null };

export const KlatchDetailsFragment_KlatchFragmentDoc = gql`
    fragment KlatchDetailsFragment_klatch on Klatch {
  id
  tags
  capacity
  connectedUsers {
    id
    bio
    fullName
    username
    photoUrl
    icebreaker
  }
}
    `;