import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { Dialog, DialogClose, DialogTitle, DialogContent } from '../../styled-components/Dialog';
import { Fieldset } from '../../styled-components/Fieldset';
import { Label } from '../../styled-components/Label';
import { Input } from '../../styled-components/Input';
import { IconButton } from '../../styled-components/IconButton';
import { Cross2Icon } from '@radix-ui/react-icons';
import InputFiller from '../../components/InputFiller/InputFiller';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';
import { KlatchModalFragment_KlatchFragment, useCreateKlatchMutation } from './__generated__/KlatchModal.gql.generated';

export const MAX_KLATCH_TAGS = 5;

interface KlatchModalProps {
  klatch: KlatchModalFragment_KlatchFragment | undefined;
  visible: boolean;
  onClose: Function;
  topicId: string;
}

gql`
  fragment KlatchModalFragment_klatch on Klatch {
    id
    tags
    capacity
    topic {
      id
    }
  }
`;

gql`
  mutation CreateKlatch($klatch: KlatchCreateInput!) {
    createKlatch(klatch: $klatch) {
      ... on Klatch {
        ...KlatchModalFragment_klatch
      }
      id
      tags
      capacity
      topic {
        id
      }
    }
  }
`

gql`
  mutation UpdateKlatch($klatch: KlatchUpdateInput!) {
    updateKlatch(klatch: $klatch) {
      ... on Klatch {
        ...KlatchModalFragment_klatch
      }
    }
  }
`

export default function KlatchModal({ visible, onClose, topicId, klatch }: KlatchModalProps) {
  const [tags, setTags] = useState('');

  const [createKlatch, { data: createKlatchData, loading }] = useCreateKlatchMutation({
    refetchQueries: ["GetCommunityTopics", "HomePage"]
  });
  // const [updateKlatch, { data: updateKlatchData }] = useUpdateKlatchMutation({
  //   refetchQueries: ["GetKlatches"]
  // });

  useEffect(() => {
    if (!klatch) return;
    setTags(klatch.tags.join(','));
  }, [klatch]);

  // const onClickUpdateKlatch = async () => {
  //   if (!klatch) return;
  //   const res = await updateKlatch({
  //     variables: {
  //       klatch: {
  //         id: klatch.id,
  //         tags: tags.split(',').map((tag) => tag.trim()).filter((tag) => tag).slice(0, MAX_KLATCH_TAGS)
  //       }
  //     }
  //   });
  //   onClose(updateKlatchData);
  // };

  const onClickCreateKlatch = async () => {
    const res = await createKlatch({
      variables: {
        klatch: {
          topicId,
          capacity: 6,
          tags: tags.split(',').map((tag) => tag.trim()).filter((tag) => tag).slice(0, MAX_KLATCH_TAGS)
        }
      }
    });
    onClose(res.data?.createKlatch);
  };

  const title = `${klatch ? 'Edit' : 'Start a'} klatch`;

  return (
    <Dialog open={visible} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogTitle>{title}</DialogTitle>
        <Fieldset css={{ marginTop: "20px" }}>
          <Label htmlFor="tags">Tags (optional)</Label>
          <Input id="tags"
            placeholder="Enter up to 5 comma separated tags..."
            value={tags}
            onChange={(event) => setTags(event.target.value)} />
        </Fieldset>
        <InputFiller textValues={["Advice", "Just Chatting", "New Face Friendly", "Debate"]} 
          onChange={(value: string) => {setTags((prev: string) => { 
            if (prev.length === 0) return value;
            return `${prev}, ${value}`;
            })}}>
        </InputFiller>
        <DialogClose asChild>
          <IconButton aria-label="Close" css={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}>
            <Cross2Icon />
          </IconButton>
        </DialogClose>
        <Box css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}>
          <Button style='subdued' disabled={loading} onClick={() => onClose()}>Cancel</Button>
          <Button style='primary' disabled={loading} onClick={() => onClickCreateKlatch()}>Create</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
