import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KlatchModalFragment_KlatchFragment = { __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, topic: { __typename?: 'Topic', id: string } };

export type CreateKlatchMutationVariables = Types.Exact<{
  klatch: Types.KlatchCreateInput;
}>;


export type CreateKlatchMutation = { __typename?: 'Mutation', createKlatch: { __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, topic: { __typename?: 'Topic', id: string } } | null };

export type UpdateKlatchMutationVariables = Types.Exact<{
  klatch: Types.KlatchUpdateInput;
}>;


export type UpdateKlatchMutation = { __typename?: 'Mutation', updateKlatch: { __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, topic: { __typename?: 'Topic', id: string } } | null };

export const KlatchModalFragment_KlatchFragmentDoc = gql`
    fragment KlatchModalFragment_klatch on Klatch {
  id
  tags
  capacity
  topic {
    id
  }
}
    `;
export const CreateKlatchDocument = gql`
    mutation CreateKlatch($klatch: KlatchCreateInput!) {
  createKlatch(klatch: $klatch) {
    ... on Klatch {
      ...KlatchModalFragment_klatch
    }
    id
    tags
    capacity
    topic {
      id
    }
  }
}
    ${KlatchModalFragment_KlatchFragmentDoc}`;
export type CreateKlatchMutationFn = Apollo.MutationFunction<CreateKlatchMutation, CreateKlatchMutationVariables>;

/**
 * __useCreateKlatchMutation__
 *
 * To run a mutation, you first call `useCreateKlatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKlatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKlatchMutation, { data, loading, error }] = useCreateKlatchMutation({
 *   variables: {
 *      klatch: // value for 'klatch'
 *   },
 * });
 */
export function useCreateKlatchMutation(baseOptions?: Apollo.MutationHookOptions<CreateKlatchMutation, CreateKlatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKlatchMutation, CreateKlatchMutationVariables>(CreateKlatchDocument, options);
      }
export type CreateKlatchMutationHookResult = ReturnType<typeof useCreateKlatchMutation>;
export type CreateKlatchMutationResult = Apollo.MutationResult<CreateKlatchMutation>;
export type CreateKlatchMutationOptions = Apollo.BaseMutationOptions<CreateKlatchMutation, CreateKlatchMutationVariables>;
export const UpdateKlatchDocument = gql`
    mutation UpdateKlatch($klatch: KlatchUpdateInput!) {
  updateKlatch(klatch: $klatch) {
    ... on Klatch {
      ...KlatchModalFragment_klatch
    }
  }
}
    ${KlatchModalFragment_KlatchFragmentDoc}`;
export type UpdateKlatchMutationFn = Apollo.MutationFunction<UpdateKlatchMutation, UpdateKlatchMutationVariables>;

/**
 * __useUpdateKlatchMutation__
 *
 * To run a mutation, you first call `useUpdateKlatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKlatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKlatchMutation, { data, loading, error }] = useUpdateKlatchMutation({
 *   variables: {
 *      klatch: // value for 'klatch'
 *   },
 * });
 */
export function useUpdateKlatchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKlatchMutation, UpdateKlatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKlatchMutation, UpdateKlatchMutationVariables>(UpdateKlatchDocument, options);
      }
export type UpdateKlatchMutationHookResult = ReturnType<typeof useUpdateKlatchMutation>;
export type UpdateKlatchMutationResult = Apollo.MutationResult<UpdateKlatchMutation>;
export type UpdateKlatchMutationOptions = Apollo.BaseMutationOptions<UpdateKlatchMutation, UpdateKlatchMutationVariables>;