import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import { UserProfileFragment_UserFragmentDoc } from '../../../shared-fragments/__generated__/UserProfile.gql.generated';
import { TopicSectionFragment_TopicFragmentDoc } from '../../../components/TopicSection/__generated__/TopicSection.gql.generated';
import { CommunitySelectFragment_CommunityFragmentDoc } from '../../../components/NavigationSidebar/CommunitySelect/__generated__/CommunitySelect.gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomePageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HomePageQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, bio: string | null, email: string, username: string | null, fullName: string | null, photoUrl: string | null, icebreaker: string | null } | null, popularPublicTopics: Array<{ __typename?: 'Topic', id: string, interestedPeopleCount: number, name: string, description: string, tags: Array<string>, nextActive: any | null, klatches: Array<{ __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, connectedUsers: Array<{ __typename?: 'User', id: string, bio: string | null, fullName: string | null, username: string | null, photoUrl: string | null, icebreaker: string | null }> | null }>, community: { __typename?: 'Community', name: string | null }, creator: { __typename?: 'User', id: string, firebaseUID: string }, upcomingSessionParticipants: Array<{ __typename?: 'TopicUser', returningAt: any | null, user: { __typename?: 'User', id: string, username: string | null, bio: string | null, icebreaker: string | null, photoUrl: string | null } }> | null }>, publicCommunities: Array<{ __typename?: 'Community', id: string, name: string | null, realNameRequired: boolean, createdAt: any, communityUserCount: number | null, description: string | null, viewerFollows: boolean | null }> };


export const HomePageDocument = gql`
    query HomePage {
  viewer {
    ...UserProfileFragment_user
  }
  popularPublicTopics {
    ...TopicSectionFragment_topic
  }
  publicCommunities {
    ...CommunitySelectFragment_community
  }
}
    ${UserProfileFragment_UserFragmentDoc}
${TopicSectionFragment_TopicFragmentDoc}
${CommunitySelectFragment_CommunityFragmentDoc}`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageQuery(baseOptions?: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;