import './App.scss';
import AudioContextProvider from './audio/AudioContextProvider';
import AuthContextProvider from './auth/AuthContextProvider';
import Router from "./layouts/Router/Router";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { ToastProvider } from './styled-components/Toast';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVERLESS_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authorization') || "";
  return {
    headers: {
      ...headers,
      ...(token && { authorization: token }),
    }
  }
});

export const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVERLESS_URL,
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

function Inner() {
  return (
    <ApolloProvider client={client}>
      <AudioContextProvider>
        <ToastProvider>
          <Router />
        </ToastProvider>
      </AudioContextProvider>
    </ApolloProvider>
  );
}


function App() {
  return (
    <AuthContextProvider>
      <Inner/>
    </AuthContextProvider>
  );
}

export default App;
