import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../auth/useAuthContext';
import { body, brand, errorRed } from '../../static/styles/colorConstants';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';
import { Fieldset } from '../../styled-components/Fieldset';
import { Input } from '../../styled-components/Input';
import { Label } from '../../styled-components/Label';
import './LoginPage.scss';

export default function LoginPage() {
  const history = useHistory();
  const { register, signIn, fbUser } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (fbUser) {
      history.push({
        pathname: '/',
        hash: window.location.hash,
        search: window.location.search,
      });
    };
  }, [fbUser]);

  async function _signIn() {
    try {
      if (!email || !password) {
        return;
      }
      await signIn(email, password);
    } catch (e) {
      setError("Invalid email or password");
    }
  }

  return (
    <>
      <div className="login-form-container">
        <div className="form">
          <Box css={{
            marginBottom: "40px",
            fontSize: "40px",
            lineHeight: "29px",
            fontWeight: 800,
            color: brand,
            cursor: "pointer",
          }}
          >
            Klatchup
          </Box>
          <Box css={{
            marginBottom: "40px",
            fontSize: "14px",
            lineHeight: "29px",
            fontWeight: 800,
            color: body,
            cursor: "pointer",
          }}
          >
            Klatch <i>noun</i> /kläCH/ <br></br> A casual gathering of people for conversation. <br></br>
            Come meet your friends and make new ones!
          </Box>
          <Fieldset>
            <Label htmlFor="email">Email</Label>
            <Input id="email" onChange={(event) => setEmail(event.target.value)} />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="password">Password</Label>
            <Input id="password" type="password" onChange={(event) => setPassword(event.target.value)} />
          </Fieldset>
          <Box css={{ color: errorRed, fontSize: "12px" }}>{error}</Box>
          <Box css={{
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "Center",
          }}>
            <Button style="primary" onClick={_signIn}>
              Sign in
            </Button>
            <Box css={{ marginTop: "10px", fontSize: "12px" }}>
              Need an account?
              <Box as="span"
                onClick={() => history.push({
                  pathname: '/register',
                  hash: window.location.hash,
                  search: window.location.search,
                })}
                css={{ marginLeft: "5px", textDecoration: "underline", cursor: "pointer" }}>
                Click Here
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
