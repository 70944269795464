import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import { KlatchDetailsFragment_KlatchFragmentDoc } from '../../KlatchGrid/__generated__/KlatchGrid.gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUpdatedKlatchDetailsQueryVariables = Types.Exact<{
  klatchId: Types.Scalars['ID'];
}>;


export type GetUpdatedKlatchDetailsQuery = { __typename?: 'Query', klatch: { __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, connectedUsers: Array<{ __typename?: 'User', id: string, bio: string | null, fullName: string | null, username: string | null, photoUrl: string | null, icebreaker: string | null }> | null } | null };

export type LeaveKlatchMutationVariables = Types.Exact<{
  input: Types.KlatchLeaveInput;
}>;


export type LeaveKlatchMutation = { __typename?: 'Mutation', leaveKlatch: string | null };


export const GetUpdatedKlatchDetailsDocument = gql`
    query GetUpdatedKlatchDetails($klatchId: ID!) {
  klatch(id: $klatchId) {
    ...KlatchDetailsFragment_klatch
  }
}
    ${KlatchDetailsFragment_KlatchFragmentDoc}`;

/**
 * __useGetUpdatedKlatchDetailsQuery__
 *
 * To run a query within a React component, call `useGetUpdatedKlatchDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdatedKlatchDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdatedKlatchDetailsQuery({
 *   variables: {
 *      klatchId: // value for 'klatchId'
 *   },
 * });
 */
export function useGetUpdatedKlatchDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUpdatedKlatchDetailsQuery, GetUpdatedKlatchDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdatedKlatchDetailsQuery, GetUpdatedKlatchDetailsQueryVariables>(GetUpdatedKlatchDetailsDocument, options);
      }
export function useGetUpdatedKlatchDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdatedKlatchDetailsQuery, GetUpdatedKlatchDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdatedKlatchDetailsQuery, GetUpdatedKlatchDetailsQueryVariables>(GetUpdatedKlatchDetailsDocument, options);
        }
export type GetUpdatedKlatchDetailsQueryHookResult = ReturnType<typeof useGetUpdatedKlatchDetailsQuery>;
export type GetUpdatedKlatchDetailsLazyQueryHookResult = ReturnType<typeof useGetUpdatedKlatchDetailsLazyQuery>;
export type GetUpdatedKlatchDetailsQueryResult = Apollo.QueryResult<GetUpdatedKlatchDetailsQuery, GetUpdatedKlatchDetailsQueryVariables>;
export const LeaveKlatchDocument = gql`
    mutation LeaveKlatch($input: KlatchLeaveInput!) {
  leaveKlatch(leaveInput: $input)
}
    `;
export type LeaveKlatchMutationFn = Apollo.MutationFunction<LeaveKlatchMutation, LeaveKlatchMutationVariables>;

/**
 * __useLeaveKlatchMutation__
 *
 * To run a mutation, you first call `useLeaveKlatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveKlatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveKlatchMutation, { data, loading, error }] = useLeaveKlatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveKlatchMutation(baseOptions?: Apollo.MutationHookOptions<LeaveKlatchMutation, LeaveKlatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveKlatchMutation, LeaveKlatchMutationVariables>(LeaveKlatchDocument, options);
      }
export type LeaveKlatchMutationHookResult = ReturnType<typeof useLeaveKlatchMutation>;
export type LeaveKlatchMutationResult = Apollo.MutationResult<LeaveKlatchMutation>;
export type LeaveKlatchMutationOptions = Apollo.BaseMutationOptions<LeaveKlatchMutation, LeaveKlatchMutationVariables>;