import './KlatchParticipant.scss';
import { PlusIcon } from "@radix-ui/react-icons";
import { body, divider } from "../../static/styles/colorConstants";
import Box from "../../styled-components/Box";
import { User } from '../../types/production.graphql';
import AvatarHoverCard from '../AvatarHoverCard/AvatarHoverCard';
import { useEffect, useState } from 'react';
import { AudioTrack, AudioTrackPublication, LocalAudioTrack, Participant, RemoteAudioTrack, RemoteTrack } from 'twilio-video';
import useAudioContext from '../../audio/useAudioContext';
import { TrackType } from '../../audio/TrackType';
import { initializeAnalyser } from '../AudioLevelIndicator/AudioLevelIndicator';

interface KlatchParticipantProps {
  rotation?: number;
  user?: User;
  audioConnected?: boolean;
}

export default function KlatchParticipant({ rotation = 0, audioConnected = false, user }: KlatchParticipantProps) {
  const { remoteParticipants, klatchRoom } = useAudioContext();
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const [audioTrack, setAudioTrack] = useState<(LocalAudioTrack | RemoteAudioTrack | null)>(null);
  const [audioTrackEnabled, setAudioTrackEnabled] = useState(false);
  const [mediaStreamTrack, setMediaStreamTrack] = useState(audioTrack?.mediaStreamTrack);
  const [twilioParticipant, setTwilioParticipant] = useState<Participant | undefined>();
  const [participantVolume, setParticipantVolume] = useState(0);

  const trackSubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Audio) {
      console.log('Table audioTrackSubscribed');
      setAudioTrack(track);
    }
  };
  const trackUnsubscribed = (track: RemoteTrack) => {
    if (track.kind === TrackType.Audio) {
      console.log('Table audioTrackUnsubscribed');
      setAudioTrack(null);
      setParticipantVolume(0);
    }
  };

  const enableAudioTrack = () => {
    setMediaStreamTrack(audioTrack?.mediaStreamTrack);
    setAudioTrackEnabled(true);
  };
  const disableAudioTrack = () => {
    setAudioTrackEnabled(false);
    setParticipantVolume(0);
  };

  useEffect(() => {
    const newAudioParticipant = (klatchRoom?.localParticipant?.identity === user?.id) ?
      klatchRoom?.localParticipant :
      remoteParticipants.find((rp) => rp.identity === user?.id);
    setTwilioParticipant(newAudioParticipant);
  }, [remoteParticipants, klatchRoom]);

  useEffect(() => {
    if (!twilioParticipant) return;
    const existingPublications = Array.from(twilioParticipant.audioTracks.values());
    const existingTracks = existingPublications.map((publication: AudioTrackPublication) => publication.track);
    const availableTrack = existingTracks.find((track: AudioTrack | null) => track !== null) || null;
    setAudioTrack(availableTrack);
  }, [twilioParticipant?.audioTracks]);

  useEffect(() => {
    if (!twilioParticipant) return;
    twilioParticipant.on('trackSubscribed', trackSubscribed);
    twilioParticipant.on('trackUnsubscribed', trackUnsubscribed);
    return () => {
      twilioParticipant.off('trackSubscribed', trackSubscribed);
      twilioParticipant.off('trackUnsubscribed', trackUnsubscribed);
    };
  }, [twilioParticipant]);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && audioTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari.
      let newMediaStream = new MediaStream([mediaStreamTrack.clone()]);

      // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
      // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
      // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
      // from a new audio device while the active audio device still has active tracks.
      const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach((track) => track.stop());
      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([mediaStreamTrack.clone()]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }
  }, [mediaStreamTrack, audioTrack]);

  useEffect(() => {
    if (audioTrack) {
      if (audioTrack.isEnabled) enableAudioTrack();
      audioTrack.on('enabled', enableAudioTrack);
      audioTrack.on('started', enableAudioTrack);
      audioTrack.on('disabled', disableAudioTrack);
    }

    return () => {
      audioTrack?.off('enabled', enableAudioTrack);
      audioTrack?.off('disabled', disableAudioTrack);
    };
  }, [audioTrack]);

  useEffect(() => {
    if (audioTrackEnabled && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = setInterval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        sampleArray.forEach((value) => { values += value; });

        const volume = Math.min(14, Math.max(0, Math.log10(values / sampleArray.length / 3) * 7));
        setParticipantVolume(volume);
      }, 100);

      return () => {
        clearInterval(timer);
      };
    } else {
      setParticipantVolume(0);
    }
  }, [audioTrackEnabled, analyser]);

  if (!user) {
    return (
      <Box css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        border: `1px dashed ${body}`,
        height: "48px",
        width: "48px",
        margin: "0 10px",
        transform: `rotate(-${rotation}deg)`
      }}>
        <PlusIcon width="40" height="40" color={divider} />
      </Box>
    );
  }

  return (
    <Box css={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      transform: `rotate(-${rotation}deg)`,
      margin: "7px"
    }}>
      <AvatarHoverCard user={user} avatarSize="large" speaking={participantVolume > 3 && audioConnected} muted={(!audioTrackEnabled || !audioTrack) && audioConnected}/>
      {
        audioConnected &&
        <Box css={{
          color: body,
          maxWidth: "70px",
          fontSize: "12px",
          fontWeight: "bold",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          marginTop: "5px",
        }}>{user.fullName || user.username || user.id}</Box>
      }
    </Box>
  );
}
