import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import { TopicSectionFragment_TopicFragmentDoc } from '../../TopicSection/__generated__/TopicSection.gql.generated';
import { TopicsNavigatorFragment_TopicFragmentDoc } from '../../NavigationSidebar/TopicsNavigator/__generated__/TopicsNavigator.gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCommunityTopicsQueryVariables = Types.Exact<{
  communityId: Types.Scalars['ID'];
}>;


export type GetCommunityTopicsQuery = { __typename?: 'Query', topics: Array<{ __typename?: 'Topic', id: string, interestedPeopleCount: number, name: string, description: string, tags: Array<string>, nextActive: any | null, klatches: Array<{ __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, connectedUsers: Array<{ __typename?: 'User', id: string, bio: string | null, fullName: string | null, username: string | null, photoUrl: string | null, icebreaker: string | null }> | null }>, community: { __typename?: 'Community', name: string | null }, creator: { __typename?: 'User', id: string, firebaseUID: string }, upcomingSessionParticipants: Array<{ __typename?: 'TopicUser', returningAt: any | null, user: { __typename?: 'User', id: string, username: string | null, bio: string | null, icebreaker: string | null, photoUrl: string | null } }> | null }> };


export const GetCommunityTopicsDocument = gql`
    query GetCommunityTopics($communityId: ID!) {
  topics(communityId: $communityId) {
    ...TopicSectionFragment_topic
    ...TopicsNavigatorFragment_topic
  }
}
    ${TopicSectionFragment_TopicFragmentDoc}
${TopicsNavigatorFragment_TopicFragmentDoc}`;

/**
 * __useGetCommunityTopicsQuery__
 *
 * To run a query within a React component, call `useGetCommunityTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityTopicsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityTopicsQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityTopicsQuery, GetCommunityTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityTopicsQuery, GetCommunityTopicsQueryVariables>(GetCommunityTopicsDocument, options);
      }
export function useGetCommunityTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityTopicsQuery, GetCommunityTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityTopicsQuery, GetCommunityTopicsQueryVariables>(GetCommunityTopicsDocument, options);
        }
export type GetCommunityTopicsQueryHookResult = ReturnType<typeof useGetCommunityTopicsQuery>;
export type GetCommunityTopicsLazyQueryHookResult = ReturnType<typeof useGetCommunityTopicsLazyQuery>;
export type GetCommunityTopicsQueryResult = Apollo.QueryResult<GetCommunityTopicsQuery, GetCommunityTopicsQueryVariables>;