import { track } from '../../app/tracking';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';

// create React component that accepts an array of text values that iterates over the array in order to create a set of buttons that add their text value to an input.
const InputFiller = ({ textValues, onChange }: any) => {
    return (
      <Box 
      css={{display:"flex", padding:"20px 0", justifyContent: "center"}}>
        {textValues.map((textValue:any, index:any) => (
          <Button
            style="outlined"
            size="small"
            key={index}
            onClick={() => {
              track('Tag Added', {
                'Tag': textValue
              });
              onChange(textValue);
            }}
          >
            {textValue}
          </Button>
        ))}
      </Box>
    );
  };

  export default InputFiller;
