import * as Types from '../../types/production.graphql';

import { gql } from '@apollo/client';
export type UserProfileFragment_UserFragment = { __typename?: 'User', id: string, bio: string | null, email: string, username: string | null, fullName: string | null, photoUrl: string | null, icebreaker: string | null };

export const UserProfileFragment_UserFragmentDoc = gql`
    fragment UserProfileFragment_user on User {
  id
  bio
  email
  username
  fullName
  photoUrl
  icebreaker
}
    `;