import {
  Route,
  Switch,
} from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import ProfilePage from '../ProfilePage/ProfilePage.gql';
import HomePage from '../Home/HomePage.gql';
import RegisterPage from '../RegisterPage/RegisterPage.gql';

export function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/" component={HomePage} />
    </Switch>
  );
}
