import * as Types from '../../../../types/production.graphql';

import { gql } from '@apollo/client';
export type TopicsNavigatorFragment_TopicFragment = { __typename?: 'Topic', id: string, interestedPeopleCount: number, name: string, description: string, tags: Array<string> };

export const TopicsNavigatorFragment_TopicFragmentDoc = gql`
    fragment TopicsNavigatorFragment_topic on Topic {
  id
  interestedPeopleCount
  name
  description
  tags
}
    `;