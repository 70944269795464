import { styled } from "@stitches/react";

const Fieldset = styled('fieldset', {
  all: 'unset',
  display: 'flex',
  gap: 20,
  alignItems: 'flex-start',
  marginBottom: 15,
});

export { Fieldset };
