import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import { KlatchDetailsFragment_KlatchFragmentDoc } from '../../KlatchGrid/__generated__/KlatchGrid.gql.generated';
import { SessionsContainerFragment_TopicFragmentDoc } from '../../KlatchGrid/SessionsContainer/__generated__/SessionsContainer.gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopicSectionFragment_TopicFragment = { __typename?: 'Topic', id: string, interestedPeopleCount: number, name: string, description: string, tags: Array<string>, nextActive: any | null, klatches: Array<{ __typename?: 'Klatch', id: string, tags: Array<string>, capacity: number | null, connectedUsers: Array<{ __typename?: 'User', id: string, bio: string | null, fullName: string | null, username: string | null, photoUrl: string | null, icebreaker: string | null }> | null }>, community: { __typename?: 'Community', name: string | null }, creator: { __typename?: 'User', id: string, firebaseUID: string }, upcomingSessionParticipants: Array<{ __typename?: 'TopicUser', returningAt: any | null, user: { __typename?: 'User', id: string, username: string | null, bio: string | null, icebreaker: string | null, photoUrl: string | null } }> | null };

export type DeleteTopicMutationVariables = Types.Exact<{
  deleteTopicId: Types.Scalars['ID'];
}>;


export type DeleteTopicMutation = { __typename?: 'Mutation', deleteTopic: string | null };

export const TopicSectionFragment_TopicFragmentDoc = gql`
    fragment TopicSectionFragment_topic on Topic {
  id
  interestedPeopleCount
  name
  description
  tags
  klatches {
    ...KlatchDetailsFragment_klatch
  }
  nextActive
  community {
    name
  }
  creator {
    id
    firebaseUID
  }
  ...SessionsContainerFragment_topic
}
    ${KlatchDetailsFragment_KlatchFragmentDoc}
${SessionsContainerFragment_TopicFragmentDoc}`;
export const DeleteTopicDocument = gql`
    mutation DeleteTopic($deleteTopicId: ID!) {
  deleteTopic(id: $deleteTopicId)
}
    `;
export type DeleteTopicMutationFn = Apollo.MutationFunction<DeleteTopicMutation, DeleteTopicMutationVariables>;

/**
 * __useDeleteTopicMutation__
 *
 * To run a mutation, you first call `useDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicMutation, { data, loading, error }] = useDeleteTopicMutation({
 *   variables: {
 *      deleteTopicId: // value for 'deleteTopicId'
 *   },
 * });
 */
export function useDeleteTopicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(DeleteTopicDocument, options);
      }
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export type DeleteTopicMutationOptions = Apollo.BaseMutationOptions<DeleteTopicMutation, DeleteTopicMutationVariables>;