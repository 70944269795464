import { styled } from "../app/theme";
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { background, brand, divider, white } from '../static/styles/colorConstants';

const StyledAvatar = styled(AvatarPrimitive.Root, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  width: 24,
  height: 24,
  borderRadius: '100%',
  boxShadow: `0 0 2px 1px ${divider}`,
  backgroundColor: background,
  variants: {
    size: {
      small: {
        width: 24,
        height: 24,
        fontSize: 12,
      },
      large: {
        width: 50,
        height: 50,
        fontSize: 15,
      }
    }
  },
  defaultVariants: {
    size: "small"
  }
});

const StyledImage = styled(AvatarPrimitive.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});

const StyledFallback = styled(AvatarPrimitive.Fallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: white,
  color: brand,
  lineHeight: 1,
  fontWeight: 500,
});

// Exports
export const Avatar = StyledAvatar;
export const AvatarImage = StyledImage;
export const AvatarFallback = StyledFallback;
