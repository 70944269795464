import { Select, SelectContent, SelectGroup, SelectItem, SelectItemIndicator, SelectItemText, SelectScrollDownButton, SelectScrollUpButton, SelectTrigger, SelectValue, SelectViewport } from '../../../styled-components/Select';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, PlusIcon, TokensIcon } from '@radix-ui/react-icons';
import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import LoadingDots from '../../../styled-components/Loading';
import Button from '../../../styled-components/Button';
import CommunityModal from '../../Modals/CommunityModal.gql';
import Box from '../../../styled-components/Box';
import { communityAtom, homeAtom, popularPublicTopicsAtom } from '../../../app/atoms';
import { useAtom } from 'jotai';
import { useHomePageQuery } from '../../../layouts/Home/__generated__/HomePage.gql.generated';
import { useJoinCurrentCommunityMutation } from './__generated__/CommunitySelect.gql.generated';
import { NamePreference } from '../../../types/production.graphql';
import { success, white } from '../../../static/styles/colorConstants';

gql`
  fragment CommunitySelectFragment_community on Community {
    id
    name
    realNameRequired
    createdAt
    communityUserCount
    description
    viewerFollows
  }
`;

gql`
  mutation JoinCurrentCommunity($input: CommunityFollowInput!) {
    followPublicCommunity(input: $input) {
      createdAt
      namePreference
    }
  }
`

export default function CommunitySelect() {
  const [community, setCommunity] = useAtom(communityAtom);
  const [, setIsHome] = useAtom(homeAtom);
  const [, setPopularPublicTopics] = useAtom(popularPublicTopicsAtom);
  const [communityModalOpen, setCommunityModalOpen] = useState(false);
  const { loading, data } = useHomePageQuery();
  const [joinCommunity] = useJoinCurrentCommunityMutation({
    refetchQueries: ["HomePage"]
  });
  const isFollowing = community?.viewerFollows || false;

  useEffect(() => {
    if (data?.popularPublicTopics) {
      setPopularPublicTopics([...data.popularPublicTopics].sort((a, b) => {
      const aConnectedUsers = a.klatches.reduce((total, current) => total + Number(current?.connectedUsers?.length || 0), 0);
      const bConnectedUsers = b.klatches.reduce((total, current) => total + Number(current?.connectedUsers?.length || 0), 0);
      return bConnectedUsers - aConnectedUsers;
    }));
    }
  }, [data]);

  return (
    <Box css= {{
      width: "100%",
      animation: "fadeIn 0.5s"
    }}>
      {
        data &&
        <>
          <Box css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Select
              defaultValue="Home"
              value={community?.id || "Home"}
              onValueChange={(v) => {
                if (v === "Home") {
                  setCommunity(undefined);
                  setIsHome(true);
                  const newurl =`${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                  window.history.pushState({path: newurl},'',newurl);
                } else {
                  setCommunity(data.publicCommunities.find((c) => c.id === v));
                  setIsHome(false);
                  const newurl =`${window.location.protocol}//${window.location.host}${window.location.pathname}?communityId=${v}`;
                  window.history.pushState({path: newurl},'',newurl);
                }
              } }>
              <SelectTrigger css={{
                justifyContent: 'flex-start'
              }}>
                <TokensIcon />
                <Box css={{
                  flex: 1,
                  marginLeft: '12px',
                  marginRight: 'auto'
                }}>
                  <SelectValue />
                </Box>
                <ChevronDownIcon />
              </SelectTrigger>
              <SelectContent>
                <SelectScrollUpButton>
                  <ChevronUpIcon />
                </SelectScrollUpButton>
                <SelectViewport>
                  <SelectGroup>
                    <Button
                      fullWidth
                      style='outlined'
                      css={{
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                      onClick={() => {
                        setCommunityModalOpen(true)
                      }}>
                      <PlusIcon />
                      Start a community
                    </Button>
                  </SelectGroup>
                  <SelectGroup>
                    <SelectItem value="Home" key="Home">
                      <SelectItemText>Home</SelectItemText>
                      <SelectItemIndicator>
                        <CheckIcon />
                      </SelectItemIndicator>
                    </SelectItem>
                    {
                      data?.publicCommunities.map((community) =>
                        <SelectItem value={community.id} key={community.id}>
                          <SelectItemText>{community.name}</SelectItemText>
                          <SelectItemIndicator>
                            <CheckIcon />
                          </SelectItemIndicator>
                        </SelectItem>
                      )
                    }
                  </SelectGroup>
                </SelectViewport>
                <SelectScrollDownButton>
                  <ChevronDownIcon />
                </SelectScrollDownButton>
              </SelectContent>
            </Select>
          </Box>
          <Box className='body-text' css={{
            marginTop: "20px",
            height: "60px",
          }}>
            {community?.description}
          </Box>
          {
            isFollowing &&
            <Box
              css={{
                margin: 'auto',
                marginTop: '20px',
                background: success,
                color: white,
                borderRadius: '15px',
                width: 'fit-content',
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                padding: "5px"
              }}>
              <CheckIcon /> Following
            </Box>
          }
          {
            community && !isFollowing &&
            <Button style="outlined" size="small" css={{
              margin: 'auto',
              marginTop: '20px'
            }}
              onClick={() => !isFollowing && joinCommunity({
                variables: {
                  input: { id: community.id, namePreference: NamePreference.Fake }
                }
              })} >
              Follow
            </Button>
          }
          <CommunityModal
            visible={communityModalOpen}
            onClose={() => setCommunityModalOpen(false)}
            community={undefined} />
        </>
      }
      {loading && <LoadingDots />}
    </Box>
  );
}
