import { useState } from 'react';
import './ProfilePage.scss';
import useAuthContext from '../../auth/useAuthContext';
import { gql } from '@apollo/client';
import { useProfilePageQuery } from './__generated__/ProfilePage.gql.generated';
import { useHistory } from 'react-router-dom';
import Box from '../../styled-components/Box';
import LoadingDots from '../../styled-components/Loading';
import ProfileForm from './ProfileForm/ProfileForm.gql';
import { User } from '../../types/production.graphql';

gql`
  query ProfilePage {
    viewer {
      ...UserProfileFragment_user
    }
  }
`;


export default function ProfilePage() {
  const history = useHistory();
  const { fbUser } = useAuthContext();

  const { loading, error, data } = useProfilePageQuery();

  if (error) history.push("/login");
  if (loading || !fbUser) {
    return <Box css={{
      display: "flex",
      height: "100vw",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
    }}><LoadingDots/></Box>
  }

  return (
    <Box css={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw"
    }}>
      <Box css={{
        width: 500
      }}>
        {data?.viewer && <ProfileForm user={data.viewer as User} onGoBack={() => history.goBack()}/>}
      </Box>
    </Box>
  );
};
