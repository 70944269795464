import { background, body, brand, errorRedHover, success, white } from "../../static/styles/colorConstants";
import { Avatar, AvatarFallback, AvatarImage } from "../../styled-components/Avatar";
import Box from "../../styled-components/Box";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "../../styled-components/HoverCard";
import { User } from "../../types/production.graphql";
import { ReactComponent as MicrophoneSlashIcon } from '../../assets/svg/microphone-slash.svg';
import { CSS } from "@stitches/react";

type AvatarHoverCardProps = {
  user: User;
  avatarSize?: "small" | "large";
  speaking?: boolean;
  muted?: boolean;
};

export default function AvatarHoverCard ({ user, avatarSize = "small", speaking = false, muted = false }: AvatarHoverCardProps) {

  const infoSectionCss: CSS = {
    background: background,
    borderRadius: "4px",
    color: "body",
    whiteSpace: "pre-wrap",
    padding: "8px",
    fontSize: "14px"
  };

  const infoSectionTitleCss = {color: brand, fontSize: "12px" };
  const name = user.fullName || user.username;
  const initials = name ? name.slice(0, 2).toUpperCase() : "";

  const borderCss = muted ? `3px solid ${errorRedHover}` : ( speaking ? `3px solid ${success}` : `3px solid ${white}`);

  return (
    <HoverCard openDelay={300}>
      <HoverCardTrigger asChild>
        <Avatar size={avatarSize} css={{ border: borderCss, position: 'relative', overflow: "visible" }}>
          { muted &&
            <Box css={{
              position: "absolute",
              bottom: 0,
              right: 0,
              height: "16px",
              width: "16px",
              borderRadius: "50%",
              fill: white,
              backgroundColor: errorRedHover
            }}>
              <MicrophoneSlashIcon />
            </Box>
          }
          <AvatarImage src={user.photoUrl || undefined} css={{ borderRadius: "50%"}}/>
          <AvatarFallback css={{ borderRadius: "50%"}}>
            {initials}
          </AvatarFallback>
        </Avatar>
      </HoverCardTrigger>
      <HoverCardContent >
        <Box css={{
          color: body,
          width: "180px",
        }}>
          <Box css={{fontWeight: "600", fontSize: "18px"}}>{user.username}</Box>
          {
            user.bio &&
            <Box css={{
              marginTop: "20px",
            }}>
              <Box css={infoSectionTitleCss}>Bio</Box>
              <Box css={infoSectionCss}>
                {user.bio}
              </Box>
            </Box>
          }
          {
            user.icebreaker &&
            <Box css={{
              marginTop: "20px",
            }}>
              <Box css={infoSectionTitleCss}>Icebreaker</Box>
              <Box css={infoSectionCss}>
                {user.icebreaker}
              </Box>
            </Box>
          }
        </Box>
      </HoverCardContent>
    </HoverCard>
  )
}
