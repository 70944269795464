import { useState } from "react";
import KlatchParticipant from "../KlatchParticipant/KlatchParticipant";
import "./KlatchCircle.scss";
import useAudioContext from "../../audio/useAudioContext";
import { gql } from "@apollo/client";
import { useJoinKlatchMutation } from "./__generated__/KlatchCircle.gql.generated";
import Button from "../../styled-components/Button";
import { SpeakerLoudIcon } from "@radix-ui/react-icons";
import { useLeaveKlatchMutation } from "../NetworkingSection/__generated__/NetworkingSection.gql.generated";
import { klatchAtom } from "../../app/atoms";
import { useAtom } from 'jotai';
import { JoinKlatchResponse, User } from "../../types/production.graphql";
import { KlatchDetailsFragment_KlatchFragment } from "../KlatchGrid/__generated__/KlatchGrid.gql.generated";
import { track } from "../../app/tracking";

interface KlatchProps {
  klatch: KlatchDetailsFragment_KlatchFragment;
  onEditRoom: () => void;
  onDeleteKlatch: () => void;
}

gql`
  mutation JoinKlatch($klatchId: ID!) {
    joinKlatch(id: $klatchId) {
      id
      topicId
      communityId
      accessToken
      connectedUsers {
        id
        bio
        fullName
        username
        photoUrl
        icebreaker
      }
    }
  }
`;

export default function KlatchCircle({
  klatch,
}: KlatchProps) {
  const [activeKlatch, setActiveKlatch] = useAtom(klatchAtom);
  const { connectKlatchAudio, klatchRoom } = useAudioContext();
  const [joining, setJoining] = useState(false);

  const [joinKlatch] = useJoinKlatchMutation();
  const [leaveKlatch] = useLeaveKlatchMutation({
    refetchQueries: [ "GetCommunityTopics", "HomePage" ]
  });

  async function joinKlatchRoom() {
    setJoining((prev) => !prev);
    await _leaveKlatch();
    const res = await joinKlatch({
      variables: {
        klatchId: klatch.id
      }
    });
    setActiveKlatch(res?.data?.joinKlatch as JoinKlatchResponse);
    if (!res?.data?.joinKlatch) return;
    await connectKlatchAudio(klatch.id, res.data.joinKlatch.accessToken);
    setJoining((prev) => !prev);
  }

  async function _leaveKlatch() {
    if (klatchRoom) {
      klatchRoom.disconnect();
    }
    if (!activeKlatch) return;
    await leaveKlatch({
      variables: {
        input: {
          id: activeKlatch.id,
          topicId: activeKlatch.topicId
        }
      }
    });
    setActiveKlatch(undefined);
  }

  return (
    <div className="outer-outline">
      <div className="spots-container">
        {
          klatch && klatch.connectedUsers && klatch.connectedUsers.map((v, i) => {
            const connectedUsersCount = klatch!.connectedUsers!.length!;
            return (
              <div
                key={klatch.id! + i}
                style={{ transform: `rotate(${(360 / connectedUsersCount) * i}deg)` }}
                className="klatch-participant-container">
                <KlatchParticipant rotation={(360 / connectedUsersCount) * i} user={v as User} />
              </div>
            );
          })
        }
        <div className="circle-outline" />
      </div>
      <div className="tags">
        {
          klatch.tags.map((tag: string, i) => (
            <div className="tag" key={klatch.id + tag + i}>#{tag}</div>
          ))
        }
      </div>
      {
        (!klatchRoom || klatchRoom.name !== klatch.id) &&
        <div className="footer">
          <Button
            fullWidth
            style="outlined"
            disabled={joining || klatch!.connectedUsers!.length >= (klatch.capacity || 6)}
            onClick={() => {
              track('Join Klatch', {
                'klatchId': klatch.id,
                'connectedUsers': klatch!.connectedUsers!.length
              });
              joinKlatchRoom();
            }}>
            <SpeakerLoudIcon /> Talk now
          </Button>
        </div>
      }
    </div>
  );
}
