import { gql } from "@apollo/client";
import { useAtom } from 'jotai';
import { useEffect, useState } from "react";
import { communityAtom, homeAtom, popularPublicTopicsAtom, topicAtom } from "../../app/atoms";
import { GetCommunityTopics_POLL_INTERVAL } from "../../app/pollIntervals";
import { body, divider } from "../../static/styles/colorConstants";
import Box from "../../styled-components/Box";
import { Toast, ToastDescription, ToastTitle, ToastViewport } from "../../styled-components/Toast";
import TopicSection from "../TopicSection/TopicSection.gql";
import { useGetCommunityTopicsLazyQuery } from "./__generated__/TopicsContent.gql.generated";

gql`
  query GetCommunityTopics($communityId: ID!) {
    topics(communityId: $communityId) {
      ...TopicSectionFragment_topic
      ...TopicsNavigatorFragment_topic
    }
  }
`;

export default function TopicsContent() {
  const [community,] = useAtom(communityAtom);
  const [isHome,] = useAtom(homeAtom);
  const [popularPublicTopics,] = useAtom(popularPublicTopicsAtom);
  const [, setCurrentTopic] = useAtom(topicAtom);
  const [getTopics, { data }] = useGetCommunityTopicsLazyQuery({
    pollInterval: GetCommunityTopics_POLL_INTERVAL
  });
  const [headings, setHeadings] = useState<NodeListOf<Element> | undefined>();
  const [showToast, setShowToast] = useState(false);
  const topicsList = (isHome && popularPublicTopics) || (data?.topics || []);

  useEffect(() => {
    if (topicsList.length <= 0) return;
    const elements = document.querySelectorAll('[id^="ts-"]');
    setHeadings(elements);
    const urlTopic = window.location.hash.split("ts-")[1];
    if (urlTopic) {
      setCurrentTopic(topicsList.find((t) => t.id === urlTopic));
    } else {
      setCurrentTopic(topicsList[0]);
    }
  }, [topicsList]);

  useEffect(() => {
    if (!community) return;
    getTopics({
      variables: {
        communityId: community.id
      }
    });
  }, [community]);

  if (!topicsList) {
    return null;
  }

  return (
    <Box css={{
      flex: 1,
      padding: "10px 25px",
      overflowY: "auto",
      height: "100%",
      '&::-webkit-scrollbar': {
        width: '5px'
      },
      '&::-webkit-scrollbar-track': {
        background: 'none'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: divider,
        borderRadius: '5px',
        height: '30px',
      }
    }}
      onScroll={(e) => {
        headings?.forEach((h) => {
          const rect = h.getBoundingClientRect();
          if (rect.top > 0 && rect.top < 400) {
            const location = window.location.toString().split('#')[0];
            history.replaceState(null, "", location + '#' + h.id);
            setCurrentTopic(topicsList.find(t => h.id.includes(t.id)));
          }
        });
      }}
    >
      {[...topicsList]
        .sort((a,b) => (b.upcomingSessionParticipants?.length || 0) - (a.upcomingSessionParticipants?.length || 0))
        .map((t) => <TopicSection key={t.id} topic={t} onLinkCopied={() => setShowToast(true)} />)}
      {
        !isHome &&
        <Box css={{
          height: "300px",
          margin: "40px",
          border: `1px dashed ${divider}`,
          color: body,
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          Don't see one you like? Start something new!
        </Box>
      }

      <Toast open={showToast} duration={1000} onOpenChange={(open) => !open && setShowToast(false)}>
        <ToastTitle>Link copied</ToastTitle>
        <ToastDescription>
          Share it with a friend!
        </ToastDescription>
      </Toast>
      <ToastViewport />
    </Box>
  );
};
