import { gql } from '@apollo/client';
import Box from '../../styled-components/Box';
import KlatchCircle from '../KlatchCircle/KlatchCircle.gql';
import NewKlatchPlaceholder from '../KlatchCircle/NewKlatchPlaceholder';
import './KlatchGrid.scss';
import SessionsContainer from './SessionsContainer/SessionsContainer.gql';
import { SessionsContainerFragment_TopicFragment } from './SessionsContainer/__generated__/SessionsContainer.gql.generated';
import { KlatchDetailsFragment_KlatchFragment } from './__generated__/KlatchGrid.gql.generated';

interface KlatchGridProps {
  klatchesFrag: KlatchDetailsFragment_KlatchFragment[];
  sessionsFrag: SessionsContainerFragment_TopicFragment;
  topicId: string;
}

gql`
  fragment KlatchDetailsFragment_klatch on Klatch {
    id
    tags
    capacity
    connectedUsers {
      id
      bio
      fullName
      username
      photoUrl
      icebreaker
    }
  }
`;

export default function KlatchGrid({ klatchesFrag, sessionsFrag, topicId }: KlatchGridProps) {
  return (
    <div className="klatch-grid">
      <Box css={{
        flex: "1",
        display: "flex",
        flexWrap: "wrap"
      }}>
        {
          klatchesFrag.map((klatch) => {
            if (!klatch.connectedUsers?.length || 0 >= 1) {
              return null;
            }
            return (
              <div className="klatch-box" key={klatch.id}>
                <KlatchCircle
                  klatch={klatch}
                  onEditRoom={() => { }}
                  onDeleteKlatch={() => { }} />
              </div>
            )
          })
        }
        <Box css={{ margin: "8px" }}>
          <SessionsContainer sessionsFrag={sessionsFrag} topicId={topicId}/>
        </Box>
        <div className="klatch-box">
          <NewKlatchPlaceholder topicId={topicId} />
        </div>
      </Box>
    </div>
  );
};
