
import { gql } from '@apollo/client';
import { throttle } from 'throttle-debounce';
import { useEffect, useMemo } from 'react';
import './SearchResults.scss';
import { useGetSearchResultsLazyQuery } from './__generated__/SearchResults.gql.generated';
import Loading from '../../../styled-components/Loading';
import Box from '../../../styled-components/Box';
import { useAtom } from 'jotai';
import { communityAtom, topicAtom } from '../../../app/atoms';
import { CommunitySelectFragment_CommunityFragment } from '../../NavigationSidebar/CommunitySelect/__generated__/CommunitySelect.gql.generated';
import { TopicsNavigatorFragment_TopicFragment } from '../../NavigationSidebar/TopicsNavigator/__generated__/TopicsNavigator.gql.generated';

type SearchResultsProps = {
  query: string;
  onClose: Function;
  resetQuery: Function;
};

gql`
  query GetSearchResults($query: String!) {
    searchResults: search(query: $query) {
      ... on Topic {
        ...SearchTopicsFragment_topic
        community {
          ... on Community {
            ...SearchCommunityFragment_community
          }
        }
      },
      ... on Community {
        ...SearchCommunityFragment_community
      }
    },
  }
`;

gql`
  fragment SearchTopicsFragment_topic on Topic {
    id
    interestedPeopleCount
    topicName: name
    topicDescription: description
    tags
}`;

gql`
  fragment SearchCommunityFragment_community on Community {
    id
    communityName: name
    realNameRequired
    createdAt
    communityUserCount
    communityDescription: description
    viewerFollows
}`;

const SearchResults = ({ query, onClose, resetQuery }: SearchResultsProps) => {
  const [community, setCommunity] = useAtom(communityAtom);
  const [topic, setTopic] = useAtom(topicAtom);
  const [getSearchResults, { loading, error, data }] = useGetSearchResultsLazyQuery();

  const debouncedResults = useMemo(() => throttle(500, getSearchResults), []);

  useEffect(() => {
    if (query.length <= 2) return;
    getSearchResults({
      variables: {
        query
      }
    });
    return () => {
      debouncedResults.cancel();
    }
  }, [query]);

  return (
    <div className='search-results'>
      {query.length > 0 && data && data.searchResults.map((result) => {
        switch (result.__typename) {
          case "Community":
            const cleanResult: CommunitySelectFragment_CommunityFragment = {
              ...result,
              name: result.communityName,
              description: result.communityDescription,
            }
            return (
              <div key={result.id} className='result-row' onClick={() => {
                const newurl =`${window.location.protocol}//${window.location.host}${window.location.pathname}?communityId=${cleanResult.id}`;
                window.history.pushState({path: newurl},'',newurl);
                setCommunity(cleanResult);
                resetQuery();
                onClose();
              }}>
                <Box css={{ width: "90px" }}>
                  <Box className='label'>{result.__typename}</Box>
                </Box>
                <Box>{result.communityName}</Box>
              </div>
            );
          case "Topic":
            const cleanTopic: TopicsNavigatorFragment_TopicFragment = {
              ...result,
              name: result.topicName,
              description: result.topicDescription,
            };
            const cleanCommunity: CommunitySelectFragment_CommunityFragment = {
              ...result.community,
              name: result.community.communityName,
              description: result.community.communityDescription,
            };
            return (
              <div key={result.id} className='result-row' onClick={() => {
                const newurl =`${window.location.protocol}//${window.location.host}${window.location.pathname}?communityId=${cleanCommunity.id}`;
                window.history.pushState({path: newurl},'',newurl);
                setCommunity(cleanCommunity);
                setTopic(cleanTopic);
                resetQuery();
                onClose();
              }}>
                <Box css={{ width: "90px" }}>
                  <Box className='label'>{result.__typename}</Box>
                </Box>
                <Box>{result.topicName}</Box>
              </div>
            );
        }
      })}

      {
        !loading && !data && query.length !== 0 && <div className='helper-text'>No results found</div>
      }
      {
        !loading && query.length === 0 && <div className='helper-text'>Type a community or topic name</div>
      }
    </div>
  );
};

export default SearchResults;
