import { ExitIcon, AvatarIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../auth/useAuthContext';
import { UserProfileFragment_UserFragment } from '../../shared-fragments/__generated__/UserProfile.gql.generated';
import { background, body, divider } from '../../static/styles/colorConstants';
import { Avatar, AvatarFallback, AvatarImage } from '../../styled-components/Avatar';
import Box from '../../styled-components/Box';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../styled-components/DropdownMenu';

type UserPillProps = {
  user?: UserProfileFragment_UserFragment
};


export default function UserPill({user}: UserPillProps) {
  const history = useHistory();
  const {
    fbUser,
    signOut,
  } = useAuthContext();

  const name = user?.username || fbUser?.email;
  const initials = name ? name.slice(0, 2).toUpperCase() : "";

  return (
    <Box css={{
      margin: "0 10px",
      padding: "0 5px",
      height: "32px",
      borderRadius: "32px",
      display: "flex",
      background: background,
      color: body,
      alignItems: "center",
      cursor: "pointer",
      transition: "background-color 0.1s ease",
      "&:hover": {
        background: divider
      }
    }}>
      {
        user && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Avatar>
                <AvatarImage src={user.photoUrl || undefined} />
                <AvatarFallback>
                  {initials}
                </AvatarFallback>
              </Avatar>
              <Box css={{
                marginLeft: "5px",
                maxWidth: "70px",
                minWidth: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}>
                {name}
              </Box>
              <TriangleDownIcon />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={() => history.push('/profile')}>
                <AvatarIcon/>
                Profile
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => signOut()}>
                <ExitIcon/>
                Sign Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )
      }
    </Box>
  )
};
