import { gql } from '@apollo/client';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../styled-components/Dialog';
import { Fieldset } from '../../styled-components/Fieldset';
import { IconButton } from '../../styled-components/IconButton';
import { Input } from '../../styled-components/Input';
import { Label } from '../../styled-components/Label';
import { Switch, SwitchThumb } from '../../styled-components/Switch';
import { TextArea } from '../../styled-components/TextArea';
import { Privacy } from '../../types/production.graphql';
import { useCreateCommunityMutation } from './__generated__/CommunityModal.gql.generated';

interface CommunityModalProps {
  visible: boolean;
  onClose: Function;
  community?: any;
}


gql`
mutation CreateCommunity($community: CommunityCreateInput!) {
  createCommunity(community: $community) {
    name
    id
    description
    privacy
    realNameRequired
    rules
  }
}
`;

export default function CommunityModal({ visible, onClose, community }: CommunityModalProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [rules, setRules] = useState('');
  const [privacy, setPrivacy] = useState(Privacy.Public);
  const [realNameRequired, setRealNameRequired] = useState(false);
  const [createCommunity, { loading, error, data }] = useCreateCommunityMutation({
    refetchQueries: ["HomePage"]
  });

  useEffect(() => {
    if (!community) return;
    setName(community.name);
    setDescription(community.description);
    setRules(community.rules);
    setPrivacy(community.privacy);
    setRealNameRequired(community.realNameRequired);
  }, [community]);

  const onClickCreateCommunity = async () => {
    const res = await createCommunity({
      variables: {
        community: {
          name,
          description,
          rules,
          privacy,
          realNameRequired,
        }
      }
    });
    onClose(res);
  };

  const saveEnabled = name && description;
  const title = `${community ? 'Edit' : 'Start a'} community`;

  return (
    <Dialog open={visible} onOpenChange={(open) => !open && onClose()}>
      <DialogContent >
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>
          Let us know what kind of community you'd like to see. We'll get back to you soon!
        </DialogDescription>
        <Fieldset>
          <Label htmlFor="name" aria-required>Name</Label>
          <Input id="name" onChange={(event) => setName(event.target.value)} />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="description" aria-required>Description</Label>
          <Input id="description" onChange={(event) => setDescription(event.target.value)}/>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="rules">Rules</Label>
          <TextArea id="rules" onChange={(event) => setRules(event.target.value)}/>
        </Fieldset>
        {/* <Fieldset>
          <Label htmlFor="privacy">Public</Label>
          <Switch defaultChecked id="s1" onCheckedChange={(val) => setPrivacy(val ? Privacy.Public : Privacy.Private)}>
            <SwitchThumb />
          </Switch>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="realName">Real Name Required</Label>
          <Switch id="s1" onCheckedChange={(val) => setRealNameRequired(val)}>
            <SwitchThumb />
          </Switch>
        </Fieldset> */}
        <DialogClose asChild>
          <IconButton aria-label="Close" css={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}>
            <Cross2Icon />
          </IconButton>
        </DialogClose>
        <Box css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}>
          <Button style='subdued' disabled={loading} onClick={() => onClose()}>Cancel</Button>
          <Button style='primary' disabled={!saveEnabled || loading} onClick={() => onClickCreateCommunity()}>Submit</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
