import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../auth/useAuthContext';
import { body, brand, errorRed } from '../../static/styles/colorConstants';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';
import { Fieldset } from '../../styled-components/Fieldset';
import { Input } from '../../styled-components/Input';
import { Label } from '../../styled-components/Label';
import LoadingDots from '../../styled-components/Loading';
import { User } from '../../types/production.graphql';
import ProfileForm from '../ProfilePage/ProfileForm/ProfileForm.gql';
import { useRegisterPageQuery } from './__generated__/RegisterPage.gql.generated';

gql`
  query RegisterPage {
    viewer {
      ...UserProfileFragment_user
    }
  }
`;

export default function RegisterPage() {
  const history = useHistory();
  const { register, fbUser } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);

  const { data, startPolling, stopPolling } = useRegisterPageQuery();

  useEffect(() => {
    if (password === "" || password2 === "") {
      return;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters");
      return;
    }
    if (password !== password2) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }, [password, password2]);

  useEffect(() => {
    if (fbUser && (data?.viewer?.username || data?.viewer?.fullName)) {
      history.push({
        pathname: '/',
        hash: window.location.hash,
        search: window.location.search,
      });
    } else if (data?.viewer) {
      // Show form to fill out profile
      setShowProfileForm(true);
      setLoading(false);
      stopPolling();
    }
  }, [fbUser, data]);

  async function _register() {
    try {
      if (!email || !password) {
        return;
      }
      setLoading(true);
      await register(email, password);
      startPolling(2000);
    } catch (e: any) {
      setError(e.message);
    }
  }

  return (
    <Box css={{
      height: "100%",
      display: "flex",
      alignItems: "flex-start",
      marginTop: "200px",
      justifyContent: "center"
    }}>
      <Box css={{
        width: "600px",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        {
          !showProfileForm &&
          <>
            <Box css={{
              marginBottom: "40px",
              fontSize: "40px",
              lineHeight: "29px",
              fontWeight: 800,
              color: brand,
              cursor: "pointer",
            }}
            >
              Sign up for Klatchup
            </Box>
            <Box css={{
            marginBottom: "40px",
            fontSize: "14px",
            lineHeight: "29px",
            fontWeight: 800,
            color: body,
            cursor: "pointer",
          }}
          >
            Bounce around our audio chatrooms of 2-6 people <br></br> to keep conversations intimate and let your voice be heard
          </Box>
            <Fieldset>
              <Label css={{ width: 150 }} htmlFor="email">Email</Label>
              <Input id="email" onChange={(event) => setEmail(event.target.value)} />
            </Fieldset>
            <Fieldset>
              <Label css={{ width: 150 }} htmlFor="password">Password</Label>
              <Input id="password" type="password" onChange={(event) => setPassword(event.target.value)} />
            </Fieldset>
            <Fieldset>
              <Label css={{ width: 150 }} htmlFor="password">Confirm Password</Label>
              <Input id="password2" type="password" onChange={(event) => setPassword2(event.target.value)} />
            </Fieldset>
            <Box css={{ color: errorRed, fontSize: "12px" }}>{error}</Box>
            <Box css={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "Center",
            }}>
              <Button css={{ display: "flex", alignItems: "center", width: "fit-content" }} style="primary" onClick={_register} disabled={!email || !password || !password2}>
                {loading ? <LoadingDots /> : "Register"}
              </Button>
              <Box css={{ marginTop: "10px", fontSize: "12px" }}>
                Already have an account?
                <Box as="span"
                  onClick={() => history.push({
                    pathname: '/login',
                    hash: window.location.hash,
                    search: window.location.search,
                  })}
                  css={{ marginLeft: "5px", textDecoration: "underline", cursor: "pointer" }}>
                  Click Here
                </Box>
              </Box>
            </Box>
          </>
        }
        {
          showProfileForm &&
          <>
            <Box css={{
              marginBottom: "40px",
              fontSize: "40px",
              lineHeight: "29px",
              fontWeight: 800,
              color: brand,
              cursor: "pointer",
            }}
            >
              Make your profile
            </Box>
            <ProfileForm user={data?.viewer as User}/>
          </>
        }
      </Box>
    </Box>
  );
};
