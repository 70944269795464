import { gql } from '@apollo/client';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { communityAtom } from '../../app/atoms';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../styled-components/Dialog';
import { Fieldset } from '../../styled-components/Fieldset';
import { IconButton } from '../../styled-components/IconButton';
import { Input } from '../../styled-components/Input';
import { Label } from '../../styled-components/Label';
import { Privacy } from '../../types/production.graphql';
import InputFiller from '../InputFiller/InputFiller';
import { useCreateTopicMutation } from './__generated__/TopicModal.gql.generated';

interface TopicModalProps {
  visible: boolean;
  onClose: Function;
  topic?: any;
}

const MAX_TOPIC_TAGS = 5;

gql`
  mutation CreateTopic($topic: TopicCreateInput!) {
    createTopic(topic: $topic) {
      name
      id
      description
      tags
    }
  }
`;

export default function TopicModal({ visible, onClose, topic }: TopicModalProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [community] = useAtom(communityAtom);
  const [createTopic, {loading, error, data}] = useCreateTopicMutation({
    refetchQueries: ["GetCommunityTopics"]
  });


  useEffect(() => {
    if (!topic) return;
    setName(topic.name);
    setDescription(topic.description);
    setTags(topic.tags.join(','));
  }, [topic]);

  const onClickCreateTopic = async () => {
    const res = await createTopic({
      variables: {
        topic: {
          name,
          description,
          communityId: community!.id,
          tags: tags.split(',').map((tag) => tag.trim()).slice(0, MAX_TOPIC_TAGS)
        }
      }
    });
    onClose(res);
  };

  const saveEnabled = name && description;
  const title = `${topic ? 'Edit' : 'Start a'} topic`;

  return (
    <Dialog open={visible} onOpenChange={(open) => !open && onClose()}>
      <DialogContent >
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>
        </DialogDescription>
        <Fieldset>
          <Label htmlFor="name" aria-required>Topic Name</Label>
          <Input id="name" onChange={(event) => setName(event.target.value)} />
        </Fieldset>
        <Fieldset>
          <Label htmlFor="description" aria-required>Description</Label>
          <Input id="description" onChange={(event) => setDescription(event.target.value)}/>
        </Fieldset>
        <Fieldset>
          <Label htmlFor="tags">Tags</Label>
          <Input id="tags"
            placeholder="Enter up to 5 comma separated tags..."
            value={tags}
            onChange={(event) => setTags(event.target.value)}/>
        </Fieldset>
        <InputFiller textValues={["Social", "Planning", "Advice", "Debate"]}
          onChange={(value: string) => {setTags((prev: string) => {
            if (prev.length === 0) return value;
            return `${prev}, ${value}`;
            })}}>
        </InputFiller>
        <DialogClose asChild>
          <IconButton aria-label="Close" css={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}>
            <Cross2Icon />
          </IconButton>
        </DialogClose>
        <Box css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}>
          <Button style='subdued' disabled={loading} onClick={() => onClose()}>Cancel</Button>
          <Button style='primary' disabled={!saveEnabled || loading} onClick={() => onClickCreateTopic()}>Save</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
