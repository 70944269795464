import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import { UserProfileFragment_UserFragmentDoc } from '../../../shared-fragments/__generated__/UserProfile.gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProfilePageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProfilePageQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, bio: string | null, email: string, username: string | null, fullName: string | null, photoUrl: string | null, icebreaker: string | null } | null };


export const ProfilePageDocument = gql`
    query ProfilePage {
  viewer {
    ...UserProfileFragment_user
  }
}
    ${UserProfileFragment_UserFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;