import { Cross2Icon, PlusIcon } from "@radix-ui/react-icons";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { times } from "../../../static/styles/timeOptions";
import Box from "../../../styled-components/Box";
import Button from "../../../styled-components/Button";
import { Input } from "../../../styled-components/Input";
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from "../../../styled-components/Popover";

type NewSessionSectionProps = {
  onSessionTimeSelected: (sessionTime: string) => void;
}

export default function NewSessionSection({ onSessionTimeSelected }: NewSessionSectionProps) {
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [today, setToday] = useState('');

  useEffect(() => {
    const now = DateTime.now().toISODate();
    setDate(now);
    setToday(now);
  }, []);

  useEffect(() => {
    if (!date || !time) return;

    const localTime = DateTime.fromISO(`${date}T${time}`, { zone: 'system' });
    const utcTime = localTime.toUTC();
    setDateTime(utcTime.toISO());
  }, [date, time]);

  return (
    <Box css={{
      marginBottom: "20px",
      height: "40px"
    }}>
      <Popover >
        <PopoverTrigger asChild css={{ borderRadius: "8px", padding: "7px 15px" }}>
          <Button
            css={{ margin: "auto" }}
            style="outlined"
            size="large">
            <PlusIcon />
            New Meet Up
          </Button>
        </PopoverTrigger>
        <PopoverContent css={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
          <Box css={{ height: "40px", width: "150px" }}>
            <datalist id="times">
              {times.map((time) => <option key={time}>{time}</option>)}
            </datalist>
            <Input id="time" type="time" list="times" css={{ height: "40px" }} value={time} onChange={(e) => setTime(e.target.value)} />
          </Box>
          <Box css={{ height: "40px", width: "150px", marginTop: "20px" }}>
            <Input id="date" type="date" css={{ height: "40px" }} min={today} value={date} onChange={(e) => setDate(e.target.value)} />
          </Box>
          <Button css={{ margin: "auto", marginTop: "20px" }} style="outlined" disabled={!dateTime} onClick={() => onSessionTimeSelected(dateTime)} >
            Schedule
          </Button>
          <PopoverClose aria-label="Close">
            <Cross2Icon />
          </PopoverClose>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
