import { Cross2Icon } from '@radix-ui/react-icons';
import Box from '../../styled-components/Box';
import Button from '../../styled-components/Button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle } from '../../styled-components/Dialog';
import { IconButton } from '../../styled-components/IconButton';

interface ConfirmationModalProps {
  visible: boolean;
  onClose: Function;
  title: string;
  description: string;
  onConfirm: () => void;
}

export default function ConfirmationModal({ visible, onClose, title, description, onConfirm }: ConfirmationModalProps) {

  return (
    <Dialog open={visible} onOpenChange={(open: boolean) => !open && onClose()}>
      <DialogContent >
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>
          {description}
        </DialogDescription>
        <DialogClose asChild>
          <IconButton aria-label="Close" css={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}>
            <Cross2Icon />
          </IconButton>
        </DialogClose>
        <Box css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}>
          <Button style='subdued' onClick={() => onClose()}>Cancel</Button>
          <Button style='primary' onClick={() => onConfirm()}>Yes</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
