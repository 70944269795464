import { styled } from "@stitches/react";
import { body, brand, divider, errorRed, errorRedHover, icon, subduedHover, white } from "../static/styles/colorConstants";

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: body,
  '& svg': {
    fill: icon,
    height: "20px",
    width: "20px"
  },
  '& + button': {
    marginLeft: "5px",
  },
  variants: {
    style: {
      square: {
        borderRadius: '8px',
      },
      minimal: {
        borderRadius: '100%',
        '&:hover': { backgroundColor: divider, color: brand },
        '&:focus': { boxShadow: `0 0 0 1px ${brand}` },
      }
    },
    color: {
      critial: {
        borderRadius: '8px',
        backgroundColor: errorRed,
        '& svg': {
          fill: white,
        },
        '&:hover': {
          backgroundColor: errorRedHover,
        },
      },
      normal: {
        '& svg': {
          fill: body,
        },
        '&:hover': {
          backgroundColor: subduedHover,
        },
      }
    },
    size: {
      small: {
        height: 25,
        width: 25,
      },
      medium: {
        height: 35,
        width: 35,
      }
    },
  },
  defaultVariants: {
    style: "minimal",
    color: "normal",
    size: "medium"
  }
});

export { IconButton };
