export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Community = {
  __typename?: 'Community';
  communityTopicCount: Maybe<Scalars['Int']>;
  communityUserCount: Maybe<Scalars['Int']>;
  communityUsers: Array<CommunityUser>;
  createdAt: Scalars['DateTime'];
  creator: User;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  privacy: Privacy;
  realNameRequired: Scalars['Boolean'];
  rules: Maybe<Scalars['String']>;
  topics: Array<Topic>;
  updatedAt: Maybe<Scalars['DateTime']>;
  viewerFollows: Maybe<Scalars['Boolean']>;
};

export type CommunityCreateInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  privacy: InputMaybe<Privacy>;
  realNameRequired: InputMaybe<Scalars['Boolean']>;
  rules: InputMaybe<Scalars['String']>;
};

export type CommunityFollowInput = {
  id: Scalars['ID'];
  namePreference: InputMaybe<NamePreference>;
};

export type CommunityUpdateInput = {
  id: Scalars['ID'];
  privacy: InputMaybe<Privacy>;
  realNameRequired: InputMaybe<Scalars['Boolean']>;
  rules: InputMaybe<Scalars['String']>;
};

export type CommunityUser = {
  __typename?: 'CommunityUser';
  community: Community;
  createdAt: Scalars['DateTime'];
  namePreference: NamePreference;
  permission: EntityPermission;
  updatedAt: Maybe<Scalars['DateTime']>;
  user: User;
};

export type CommunityUserUpdateInput = {
  communityId: Scalars['ID'];
  namePreference: InputMaybe<NamePreference>;
};

export enum EntityPermission {
  Admin = 'admin',
  Moderator = 'moderator',
  Owner = 'owner',
  Participant = 'participant'
}

export type JoinKlatchResponse = {
  __typename?: 'JoinKlatchResponse';
  accessToken: Scalars['String'];
  communityId: Scalars['ID'];
  connectedUsers: Maybe<Array<User>>;
  id: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type Klatch = {
  __typename?: 'Klatch';
  capacity: Maybe<Scalars['Int']>;
  connectedUsers: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  creator: User;
  id: Scalars['ID'];
  klatchUsers: Array<KlatchUser>;
  tags: Array<Scalars['String']>;
  topic: Topic;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type KlatchCreateInput = {
  capacity: InputMaybe<Scalars['Int']>;
  tags: InputMaybe<Array<Scalars['String']>>;
  topicId: Scalars['ID'];
};

export type KlatchLeaveInput = {
  id: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type KlatchUpdateInput = {
  capacity: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  tags: InputMaybe<Array<Scalars['String']>>;
};

export type KlatchUser = {
  __typename?: 'KlatchUser';
  Klatch: Klatch;
  createdAt: Scalars['DateTime'];
  lastJoined: Maybe<Scalars['DateTime']>;
  permission: EntityPermission;
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCommunity: Maybe<Community>;
  createKlatch: Maybe<Klatch>;
  createTopic: Maybe<Topic>;
  createUser: Maybe<User>;
  deleteCommunity: Maybe<Scalars['ID']>;
  deleteKlatch: Maybe<Scalars['ID']>;
  deleteTopic: Maybe<Scalars['ID']>;
  deleteUser: Maybe<Scalars['ID']>;
  followPublicCommunity: Maybe<CommunityUser>;
  joinKlatch: JoinKlatchResponse;
  joinPublicTopic: Maybe<TopicUser>;
  leaveKlatch: Maybe<Scalars['String']>;
  updateCommunity: Maybe<Community>;
  updateCommunityUser: Maybe<CommunityUser>;
  updateKlatch: Maybe<Klatch>;
  updateTopic: Maybe<Topic>;
  updateUser: Maybe<User>;
};


export type MutationCreateCommunityArgs = {
  community: CommunityCreateInput;
};


export type MutationCreateKlatchArgs = {
  klatch: KlatchCreateInput;
};


export type MutationCreateTopicArgs = {
  topic: TopicCreateInput;
};


export type MutationCreateUserArgs = {
  user: UserCreateInput;
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteKlatchArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTopicArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationFollowPublicCommunityArgs = {
  input: CommunityFollowInput;
};


export type MutationJoinKlatchArgs = {
  id: Scalars['ID'];
};


export type MutationJoinPublicTopicArgs = {
  input: TopicJoinInput;
};


export type MutationLeaveKlatchArgs = {
  leaveInput: KlatchLeaveInput;
};


export type MutationUpdateCommunityArgs = {
  community: CommunityUpdateInput;
};


export type MutationUpdateCommunityUserArgs = {
  communityUser: CommunityUserUpdateInput;
};


export type MutationUpdateKlatchArgs = {
  klatch: KlatchUpdateInput;
};


export type MutationUpdateTopicArgs = {
  topic: TopicUpdateInput;
};


export type MutationUpdateUserArgs = {
  user: UserUpdateInput;
};

export enum NamePreference {
  Fake = 'fake',
  Real = 'real'
}

export enum Privacy {
  Deleted = 'deleted',
  Private = 'private',
  Public = 'public'
}

export type Query = {
  __typename?: 'Query';
  community: Maybe<Community>;
  klatch: Maybe<Klatch>;
  klatches: Array<Klatch>;
  popularPublicTopics: Array<Topic>;
  publicCommunities: Array<Community>;
  search: Array<Searchable>;
  topic: Maybe<Topic>;
  topics: Array<Topic>;
  viewer: Maybe<User>;
};


export type QueryCommunityArgs = {
  id: Scalars['ID'];
};


export type QueryKlatchArgs = {
  id: Scalars['ID'];
};


export type QueryKlatchesArgs = {
  topicId: Scalars['ID'];
};


export type QuerySearchArgs = {
  query: InputMaybe<Scalars['String']>;
};


export type QueryTopicArgs = {
  id: Scalars['ID'];
};


export type QueryTopicsArgs = {
  communityId: Scalars['ID'];
};

export type Searchable = Community | Topic;

export type Topic = {
  __typename?: 'Topic';
  community: Community;
  createdAt: Scalars['DateTime'];
  creator: User;
  description: Scalars['String'];
  id: Scalars['ID'];
  interestedPeopleCount: Scalars['Int'];
  klatches: Array<Klatch>;
  name: Scalars['String'];
  nextActive: Maybe<Scalars['DateTime']>;
  status: TopicStatus;
  tags: Array<Scalars['String']>;
  topicUsers: Array<TopicUser>;
  upcomingSessionParticipants: Maybe<Array<TopicUser>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type TopicCreateInput = {
  communityId: Scalars['ID'];
  description: Scalars['String'];
  name: Scalars['String'];
  tags: InputMaybe<Array<Scalars['String']>>;
};

export type TopicJoinInput = {
  id: Scalars['ID'];
  returningAt: InputMaybe<Scalars['DateTime']>;
  sendNotifications: InputMaybe<Scalars['Boolean']>;
};

export enum TopicStatus {
  Deleted = 'deleted',
  Inactive = 'inactive',
  Live = 'live'
}

export type TopicUpdateInput = {
  description: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['String']>>;
};

export type TopicUser = {
  __typename?: 'TopicUser';
  createdAt: Scalars['DateTime'];
  permission: EntityPermission;
  returningAt: Maybe<Scalars['DateTime']>;
  sendNotifications: Scalars['Boolean'];
  topic: Topic;
  updatedAt: Maybe<Scalars['DateTime']>;
  user: User;
};

export type User = {
  __typename?: 'User';
  bio: Maybe<Scalars['String']>;
  communities: Array<Community>;
  communityUsers: Array<CommunityUser>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  emailVerified: Maybe<Scalars['Boolean']>;
  firebaseUID: Scalars['String'];
  fullName: Maybe<Scalars['String']>;
  icebreaker: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  klatchUsers: Array<KlatchUser>;
  klatches: Array<Klatch>;
  photoUrl: Maybe<Scalars['String']>;
  topicUsers: Array<TopicUser>;
  topics: Array<Topic>;
  updatedAt: Maybe<Scalars['DateTime']>;
  username: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  firebaseUID: Scalars['String'];
  fullName: InputMaybe<Scalars['String']>;
  photoUrl: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
};

export type UserUpdateInput = {
  bio: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  fullName: InputMaybe<Scalars['String']>;
  icebreaker: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  photoUrl: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
};
