import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import { UserProfileFragment_UserFragmentDoc } from '../../../shared-fragments/__generated__/UserProfile.gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RegisterPageQuery = { __typename?: 'Query', viewer: { __typename?: 'User', id: string, bio: string | null, email: string, username: string | null, fullName: string | null, photoUrl: string | null, icebreaker: string | null } | null };


export const RegisterPageDocument = gql`
    query RegisterPage {
  viewer {
    ...UserProfileFragment_user
  }
}
    ${UserProfileFragment_UserFragmentDoc}`;

/**
 * __useRegisterPageQuery__
 *
 * To run a query within a React component, call `useRegisterPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisterPageQuery(baseOptions?: Apollo.QueryHookOptions<RegisterPageQuery, RegisterPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterPageQuery, RegisterPageQueryVariables>(RegisterPageDocument, options);
      }
export function useRegisterPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterPageQuery, RegisterPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterPageQuery, RegisterPageQueryVariables>(RegisterPageDocument, options);
        }
export type RegisterPageQueryHookResult = ReturnType<typeof useRegisterPageQuery>;
export type RegisterPageLazyQueryHookResult = ReturnType<typeof useRegisterPageLazyQuery>;
export type RegisterPageQueryResult = Apollo.QueryResult<RegisterPageQuery, RegisterPageQueryVariables>;