import { keyframes, styled } from "@stitches/react"
import { body } from "../static/styles/colorConstants";

const ldsEllipsis1 = keyframes({
  '0%': {
    'transform': 'scale(0)'
  },
  '100%': {
    'transform': 'scale(1)'
  }
});
const ldsEllipsis2 = keyframes({
  '0%': {
    'transform': 'translate(0, 0)'
  },
  '100%': {
    'transform': 'translate(24px, 0)'
  }
});
const ldsEllipsis3 = keyframes({
  '0%': {
    'transform': 'scale(1)'
  },
  '100%': {
    'transform': 'scale(0)'
  }
});

const LoadingContainer = styled('div', {
  position: "relative",
  width: "80px",
  margin: 'auto',
  height: "20px",
  color: body,
  '& div': {
    position: "absolute",
    top: "5px",
    width: "10px",
    height: "10px",
    borderRadius: "12px",
    background: body,
    "animation-timing-function": "cubic-bezier(0, 1, 1, 0)",
  },
  '& :nth-child(1)': {
    left: "8px",
    animation: `${ldsEllipsis1} 0.6s infinite`,
  },
  '& :nth-child(2)': {
    left: "8px",
    animation: `${ldsEllipsis2} 0.6s infinite`,
  },
  '& :nth-child(3)': {
    left: "32px",
    animation: `${ldsEllipsis2} 0.6s infinite`,
  },
  '& :nth-child(4)': {
    left: "56px",
    animation: `${ldsEllipsis3} 0.6s infinite`,
  },
})

const LoadingDots = () => {
  return (
    <LoadingContainer>
      <div></div><div></div><div></div><div></div>
    </LoadingContainer>
  );
};

export default LoadingDots;
