import * as Types from '../../../../types/production.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComeBackLaterMutationVariables = Types.Exact<{
  input: Types.TopicJoinInput;
}>;


export type ComeBackLaterMutation = { __typename?: 'Mutation', joinPublicTopic: { __typename?: 'TopicUser', returningAt: any | null, updatedAt: any | null, createdAt: any } | null };

export type SessionsContainerFragment_TopicFragment = { __typename?: 'Topic', name: string, upcomingSessionParticipants: Array<{ __typename?: 'TopicUser', returningAt: any | null, user: { __typename?: 'User', id: string, username: string | null, bio: string | null, icebreaker: string | null, photoUrl: string | null } }> | null };

export const SessionsContainerFragment_TopicFragmentDoc = gql`
    fragment SessionsContainerFragment_topic on Topic {
  name
  upcomingSessionParticipants {
    returningAt
    user {
      id
      username
      bio
      icebreaker
      photoUrl
    }
  }
}
    `;
export const ComeBackLaterDocument = gql`
    mutation ComeBackLater($input: TopicJoinInput!) {
  joinPublicTopic(input: $input) {
    returningAt
    updatedAt
    createdAt
  }
}
    `;
export type ComeBackLaterMutationFn = Apollo.MutationFunction<ComeBackLaterMutation, ComeBackLaterMutationVariables>;

/**
 * __useComeBackLaterMutation__
 *
 * To run a mutation, you first call `useComeBackLaterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComeBackLaterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [comeBackLaterMutation, { data, loading, error }] = useComeBackLaterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComeBackLaterMutation(baseOptions?: Apollo.MutationHookOptions<ComeBackLaterMutation, ComeBackLaterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ComeBackLaterMutation, ComeBackLaterMutationVariables>(ComeBackLaterDocument, options);
      }
export type ComeBackLaterMutationHookResult = ReturnType<typeof useComeBackLaterMutation>;
export type ComeBackLaterMutationResult = Apollo.MutationResult<ComeBackLaterMutation>;
export type ComeBackLaterMutationOptions = Apollo.BaseMutationOptions<ComeBackLaterMutation, ComeBackLaterMutationVariables>;