import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth, createUserWithEmailAndPassword,
        signInWithEmailAndPassword, signOut,
        onAuthStateChanged, User, Auth } from "firebase/auth";
import mixpanel from 'mixpanel-browser';
import { client } from '../App';
import { track } from '../app/tracking';

export interface IAuthContext {
  authToken: string | undefined;
  register: { (emailText: string, passwordText: string): Promise<void> };
  signIn: { (emailText: string, passwordText: string): Promise<void> };
  signOut: Function;
  fbUser: User | undefined;
}

export const AuthContext = React.createContext<IAuthContext>(null!);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {

  const [firebaseAuth, setFirebaseAuth] = useState<Auth | undefined>();
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [fbUser, setFbUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const app = initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    });
    const auth = getAuth(app);
    const analytics = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID && getAnalytics(app);
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID || "");
    setFirebaseAuth(auth);
    if (auth.currentUser) {
      initializeUser(auth.currentUser);
    }
    const tokenRefreshInt = setInterval(async () => {
      track('Session Refresh', {
        'Firebase User Id': auth?.currentUser?.uid
      });
      const newToken = await auth?.currentUser?.getIdToken(true);
      setAuthToken(newToken);
      localStorage.setItem('authorization', `Bearer ${newToken}`);
    }, 59 * 60 * 60); // 59 minutes token refresh
    const unsubscribe = onAuthStateChanged(auth, async (updatedFbUser) => {
      if (updatedFbUser) {
        initializeUser(updatedFbUser);
        track('Session Start', {
          'Firebase User Id': updatedFbUser.uid
        });
      } else {
        setFbUser(undefined);
        setAuthToken(undefined);
        localStorage.setItem('authorization', "");
      }
    });
    return () => {
      clearInterval(tokenRefreshInt);
      unsubscribe();
    } ;
  }, []);

  async function initializeUser(user: User) {
    setFbUser(user);
    const fbToken = await user.getIdToken(true);
    setAuthToken(fbToken);
    localStorage.setItem('authorization', `Bearer ${fbToken}`);
  }

  async function register(emailText: string, passwordText: string) {
    try {
      const credential = await createUserWithEmailAndPassword(firebaseAuth!!, emailText, passwordText);
      setFbUser(credential.user);
    } catch (e) {
      console.error(e);
      throw(e);
    }
  }

  async function signIn(emailText: string, passwordText: string) {
    try {
      const credential = await signInWithEmailAndPassword(firebaseAuth!!, emailText, passwordText);
      setFbUser(credential.user);
    } catch (e) {
      console.error(e);
      throw(e);
    }
  }

  async function _signOut() {
    try {
      await signOut(firebaseAuth!!);
      client.clearStore();
      setFbUser(undefined);
      setAuthToken(undefined);
      localStorage.setItem('authorization', "");
    } catch (e) {
      console.error(e);
      throw(e);
    }
  }

  return (
    <AuthContext.Provider value={{
      authToken,
      register,
      signIn,
      signOut: _signOut,
      fbUser
    }}>
      { firebaseAuth && children }
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
