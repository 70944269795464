import * as Types from '../../../types/production.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type JoinKlatchMutationVariables = Types.Exact<{
  klatchId: Types.Scalars['ID'];
}>;


export type JoinKlatchMutation = { __typename?: 'Mutation', joinKlatch: { __typename?: 'JoinKlatchResponse', id: string, topicId: string, communityId: string, accessToken: string, connectedUsers: Array<{ __typename?: 'User', id: string, bio: string | null, fullName: string | null, username: string | null, photoUrl: string | null, icebreaker: string | null }> | null } };


export const JoinKlatchDocument = gql`
    mutation JoinKlatch($klatchId: ID!) {
  joinKlatch(id: $klatchId) {
    id
    topicId
    communityId
    accessToken
    connectedUsers {
      id
      bio
      fullName
      username
      photoUrl
      icebreaker
    }
  }
}
    `;
export type JoinKlatchMutationFn = Apollo.MutationFunction<JoinKlatchMutation, JoinKlatchMutationVariables>;

/**
 * __useJoinKlatchMutation__
 *
 * To run a mutation, you first call `useJoinKlatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinKlatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinKlatchMutation, { data, loading, error }] = useJoinKlatchMutation({
 *   variables: {
 *      klatchId: // value for 'klatchId'
 *   },
 * });
 */
export function useJoinKlatchMutation(baseOptions?: Apollo.MutationHookOptions<JoinKlatchMutation, JoinKlatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinKlatchMutation, JoinKlatchMutationVariables>(JoinKlatchDocument, options);
      }
export type JoinKlatchMutationHookResult = ReturnType<typeof useJoinKlatchMutation>;
export type JoinKlatchMutationResult = Apollo.MutationResult<JoinKlatchMutation>;
export type JoinKlatchMutationOptions = Apollo.BaseMutationOptions<JoinKlatchMutation, JoinKlatchMutationVariables>;