import { gql } from "@apollo/client";
import { CalendarIcon } from "@radix-ui/react-icons";
import { DateTime } from "luxon";
import { background, body, brand, divider, white } from "../../../static/styles/colorConstants";
import { Avatar, AvatarFallback } from "../../../styled-components/Avatar";
import Box from "../../../styled-components/Box";
import { IconButton } from "../../../styled-components/IconButton";
import { User } from "../../../types/production.graphql";
import AvatarHoverCard from "../../AvatarHoverCard/AvatarHoverCard";

gql`
  fragment SessionRowFragment_topic on Topic {
    upcomingSessionParticipants {
      returningAt
      user {
        id
        username
        bio
        icebreaker
        photoUrl
      }
    }
  }
`;

type SessionRowProps = {
  time: string;
  users: User[];
  onSessionJoinClicked: () => void;
};

const DEFAULT_VISIBLE_USERS = 3;

export default function SessionRow({ time, users, onSessionJoinClicked }: SessionRowProps) {
  return (
    <Box css={{
      height: "50px", color: body,
      display: "flex", alignItems: "center",
      width: "100%",
      borderBottom: `1px solid ${background}`,
      marginTop: "4px"
    }}>
      <Box css={{ fontSize: "17px", color: "black" }}>
        {DateTime.fromISO(time).toFormat(`hh:mm a LLL dd`)}
      </Box>
      {
        DateTime.fromISO(time).toMillis() < Date.now() &&
        <Box css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: `1px solid ${divider}`,
          color: white,
          background: brand,
          borderRadius: 15,
          marginLeft: 10,
          padding: '5px 10px',
          fontSize: 12
        }}>
          Live
        </Box>
      }
      <Box>
        
      </Box>
      <Box css={{ display: "flex", alignItems: "center", flex: 1, justifyContent: "flex-end" }}>
        <Box css={{ display: "flex", alignItems: "center" }}>
          {
            [...users]
              .sort((a, b) => Number(Boolean(b.bio)) - Number(Boolean(a.bio)))
              .sort((a, b) => Number(Boolean(b.icebreaker)) - Number(Boolean(a.icebreaker)))
              .slice(0, DEFAULT_VISIBLE_USERS).map((user) => (
              <Box css={{ margin: "2px" }} key={user.id}>
                <AvatarHoverCard user={user}/>
              </Box>
            ))
          }
          {
            users.length > DEFAULT_VISIBLE_USERS &&
            <Avatar size="small" css={{ border: `3px solid ${white}`, margin: "2px" }}>
              <AvatarFallback>
                {`+ ${users.length - DEFAULT_VISIBLE_USERS}`}
              </AvatarFallback>
            </Avatar>
          }
        </Box>
      </Box>
      <IconButton css={{marginLeft: "10px"}} onClick={onSessionJoinClicked}><CalendarIcon /></IconButton>
    </Box>
  );
}
