import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { UserProfileFragment_UserFragment } from '../../../shared-fragments/__generated__/UserProfile.gql.generated';
import Box from '../../../styled-components/Box';
import Button from '../../../styled-components/Button';
import { Fieldset } from '../../../styled-components/Fieldset';
import { Input } from '../../../styled-components/Input';
import { Label } from '../../../styled-components/Label';
import { TextArea } from '../../../styled-components/TextArea';
import { useUpdateUserMutation } from './__generated__/ProfileForm.gql.generated';
import { Toast, ToastTitle, ToastViewport } from "../../../styled-components/Toast";



gql`
  mutation UpdateUser($userUpdateInput: UserUpdateInput!) {
    updateUser(user: $userUpdateInput) {
      ... on User {
        ...UserProfileFragment_user
      }
    }
  }
`;

interface ProfileFormProps {
  user: UserProfileFragment_UserFragment;
  onGoBack?: () => void;
}

export default function ProfileForm({ user, onGoBack }: ProfileFormProps) {
  const [fullName, setFullName] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [icebreaker, setIcebreaker] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [updateUser, { loading, error, data }] = useUpdateUserMutation({
    refetchQueries: [
      "ProfilePage",
      "HomePage"
    ],
  });

  useEffect(() => {
    if (!user) return;
    setFullName(user.fullName || '');
    setBio(user.bio || '');
    setEmail(user.email);
    setUsername(user.username || '');
    setIcebreaker(user.icebreaker || '');
  }, [user]);


  const saveEnabled = username && username !== "";

  const onClickUpdateUser = async () => {
    const res = await updateUser({
      variables: {
        userUpdateInput: {
          id: user?.id || "",
          fullName,
          bio,
          icebreaker,
          username,
          email,
          photoUrl: null,
        }
      }
    });
  };


  return (
    <Box>
      <Fieldset>
        <Label htmlFor="username" aria-required>Username</Label>
        <Input id="username" defaultValue={username} placeholder="Can be used instead of your real name" onChange={(event) => setUsername(event.target.value)} />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="name">Full Name</Label>
        <Input id="name" defaultValue={fullName} placeholder="First name Last name" onChange={(event) => setFullName(event.target.value)} />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="icebreaker">Icebreaker</Label>
        <Input id="icebreaker" defaultValue={icebreaker} placeholder="How do you like to start conversations?" onChange={(event) => setIcebreaker(event.target.value)} />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="bio">Bio</Label>
        <TextArea id="bio" defaultValue={bio} placeholder="Share a little about your background and interests" onChange={(event) => setBio(event.target.value)} />
      </Fieldset>
      <Fieldset>
        <Label htmlFor="email">Email</Label>
        <Input id="email" disabled defaultValue={email}/>
      </Fieldset>
      <Box css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}>
        {
          onGoBack &&
          <Button style='subdued' onClick={() => onGoBack()}>Back</Button>
        }
        <Button style='primary' disabled={!saveEnabled || loading} onClick={() => {
          onClickUpdateUser();
          setShowToast(true);
        }}>
          Save
        </Button>
      </Box>
      <Toast open={showToast} duration={1000} onOpenChange={(open) => !open && setShowToast(false)}>
        <ToastTitle>Your profile has been saved</ToastTitle>
      </Toast>
      <ToastViewport />
    </Box>
  )
}
