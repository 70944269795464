import {
  useEffect,
  useState,
  useCallback,
  createContext,
  ReactNode,
} from 'react';
import Video, {
  Room,
  LocalTrack,
  LocalAudioTrack,
  RemoteParticipant,
  ConnectOptions,
  CreateLocalTrackOptions,
} from 'twilio-video';
import ParticipantAudioTrack from './ParticipantAudioTrack';

const { connect } = require('twilio-video');

export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';

export interface IAudioContext {
  klatchRoom: Room | undefined;
  remoteParticipants: RemoteParticipant[];
  localAudioTrack: LocalAudioTrack | undefined;
  createLocalAudioTrack: Function;
  removeLocalAudioTrack: Function;
  connectKlatchAudio: (klatchId: string, klatchToken: string) => Promise<void>;
  devices: MediaDeviceInfo[];
  activeSinkId: string | null;
  setActiveSinkId: Function;
  disposeKlatchRoom: Function;
}

export const AudioContext = createContext<IAudioContext>(null!);

interface AudioProviderProps {
  children: ReactNode;
}

const AudioContextProvider = ({ children }: AudioProviderProps) => {
  const [klatchRoom, setKlatchRoom] = useState<Room | undefined>(undefined);
  const [remoteParticipants, setRemoteParticipants] = useState<RemoteParticipant[]>([]);
  const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack | undefined>();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeSinkId, setActiveSinkId] = useState<string | null>(window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY) || "default");

  const connectionOptions: ConnectOptions = {
    audio: true,
    maxAudioBitrate: 16000, // For music remove this line
    networkQuality: { local: 1, remote: 1 },
  };

  const removeLocalAudioTrack = useCallback(() => {
    if (localAudioTrack) {
      localAudioTrack.stop();
      klatchRoom?.localParticipant?.unpublishTrack(localAudioTrack as LocalTrack);
      console.log('Stopped local audio');
    }
    setLocalAudioTrack(undefined);
  }, [localAudioTrack, klatchRoom]);

  const createLocalAudioTrack = useCallback(async () => {
    const audioMediaTrack = await navigator.mediaDevices.getUserMedia({ audio: true });

    const options: CreateLocalTrackOptions = {};
    const audioInputDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const deviceIdExists = audioMediaTrack.getAudioTracks().filter((audioTrack) => audioTrack.id === audioInputDeviceId).length > 0;
    if (deviceIdExists && audioInputDeviceId) {
      options.deviceId = { exact: audioInputDeviceId };
    }else {
      const newDeviceId = audioMediaTrack.getAudioTracks()[0].getSettings().deviceId;
      options.deviceId = { exact: newDeviceId };
      window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId as string);
    }
    return Video.createLocalAudioTrack(options).then((newAudioTrack: LocalAudioTrack) => {
      setLocalAudioTrack(newAudioTrack);
      if (klatchRoom) {
        klatchRoom.localParticipant?.publishTrack(newAudioTrack);
      }
    });
  }, [klatchRoom]);

  const connectKlatchAudio = async (klatchId: string, klatchToken: string) => {
    console.log('Connecting to Klatch...');
    let audioMediaTrack;
    let audioInputId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    try {
      audioMediaTrack = await navigator.mediaDevices.getUserMedia({ audio: true });

      const deviceIdExists = audioMediaTrack?.getAudioTracks().filter((audioTrack) => audioTrack.id === audioInputId).length > 0;
      if (!deviceIdExists) {
        audioInputId = audioMediaTrack.getAudioTracks()[0].getSettings().deviceId as string;
        window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, audioInputId);
      }
    } catch (e) {
      audioInputId = null;
    }

    return Video.createLocalTracks({
      audio: audioInputId ? { deviceId: { exact: audioInputId }} as CreateLocalTrackOptions : false,
      video: false,
    }).then(async (tracks: LocalTrack[]) => {
      setLocalAudioTrack(tracks.find((track) => track.kind === 'audio') as LocalAudioTrack);
      const connectedKlatch = await connect(klatchToken, {
        name: klatchId,
        tracks,
        ...connectionOptions,
      });
      setKlatchRoom(connectedKlatch);
      setRemoteParticipants(Array.from(connectedKlatch.participants.values()));
      const disconnect = () => connectedKlatch?.disconnect();
      window.addEventListener('beforeunload', disconnect);
    }).catch(e => {
      console.error(e);
    });
  };

  const addRemoteParticipant = (participant: RemoteParticipant) => {
    setRemoteParticipants(remoteParticipants?.concat(participant));
  };

  const removeRemoteParticipant = (participant: RemoteParticipant) => {
    setRemoteParticipants(remoteParticipants?.filter((p) => p.identity !== participant.identity));
  };

  const disposeKlatchRoom = (room: Room) => {
    removeLocalAudioTrack();
    if (klatchRoom) {
      klatchRoom.disconnect();
    }
    setKlatchRoom(undefined);
    setRemoteParticipants([]);
  };

  useEffect(() => {
    if (navigator.mediaDevices) {
      const getDevices = () => {
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            setDevices(devices);
          }).catch((e) => {
            console.error('Device error', e);
          });
      }
      getDevices();
      navigator.mediaDevices.addEventListener('devicechange', getDevices);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices);
      };
    }
  }, []);

  useEffect(() => {
    if (klatchRoom) {
      klatchRoom.on('participantConnected', addRemoteParticipant);
      klatchRoom.on('participantDisconnected', removeRemoteParticipant);
      klatchRoom.on('disconnected', disposeKlatchRoom);
    }

    return () => {
      klatchRoom?.off('participantConnected', addRemoteParticipant);
      klatchRoom?.off('participantDisconnected', removeRemoteParticipant);
      klatchRoom?.off('disconnected', disposeKlatchRoom);
    };
  }, [klatchRoom, remoteParticipants]);

  useEffect(() => {
    const selectedSinkId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
    const audioOutputDevices = devices.filter((device) => device.kind === 'audiooutput');
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      (device) => selectedSinkId && device.deviceId === selectedSinkId,
    );
    if (hasSelectedAudioOutputDevice) {
      setActiveSinkId(selectedSinkId!);
    }
  }, [devices]);

  return (
    <AudioContext.Provider
      value={{
        klatchRoom,
        remoteParticipants,
        localAudioTrack,
        createLocalAudioTrack,
        removeLocalAudioTrack,
        connectKlatchAudio,
        devices,
        activeSinkId,
        setActiveSinkId,
        disposeKlatchRoom,
      }}
    >
      {children}
      {
        klatchRoom?.participants &&
        // @ts-ignore
        Array.from(klatchRoom.participants.values()).map((participant) => <ParticipantAudioTrack key={participant.identity} participant={participant} />)
      }
    </AudioContext.Provider>
  );
};

export default AudioContextProvider;
