import { useState } from "react";
import { gql } from "@apollo/client";
import { CalendarIcon, CrossCircledIcon, Share1Icon } from "@radix-ui/react-icons";
import { DateTime } from "luxon";
import { body, brand, white } from "../../static/styles/colorConstants";
import Box from "../../styled-components/Box";
import Button from "../../styled-components/Button";
import './TopicSection.scss';
import { IconButton } from "../../styled-components/IconButton";
import KlatchGrid from "../KlatchGrid/KlatchGrid.gql";
import { TopicSectionFragment_TopicFragment as TopicSectionFragment, useDeleteTopicMutation } from "./__generated__/TopicSection.gql.generated";
import { homeAtom } from '../../app/atoms';
import { useAtom } from 'jotai';
import useAuthContext from "../../auth/useAuthContext";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { track } from "mixpanel-browser";

gql`
  fragment TopicSectionFragment_topic on Topic {
    id
    interestedPeopleCount
    name
    description
    tags
    klatches {
      ...KlatchDetailsFragment_klatch
    }
    nextActive
    community {
      name
    }
    creator {
      id
      firebaseUID
    }
    ...SessionsContainerFragment_topic
  }
`;

gql`
  mutation DeleteTopic($deleteTopicId: ID!) {
    deleteTopic(id: $deleteTopicId)
  }
`

type TopicSectionProps = {
  topic: TopicSectionFragment;
  onLinkCopied: Function;
}

export default function TopicSection({ topic, onLinkCopied }: TopicSectionProps) {
  const { fbUser } = useAuthContext();
  const [deleteTopic] = useDeleteTopicMutation({
    refetchQueries: ["GetCommunityTopics", "HomePage"]
  });
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const returningAt = topic.nextActive._min.returningAt;
  const [isHome] = useAtom(homeAtom);

  return (
    <Box css={{ minHeight: 500 }} id={`ts-${topic.id}`}>
      <Box css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}>
        <Box css={{
          display: "flex",
        }}>
          <Box css={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "29px",
            marginRight: "20px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: body,
          }} id={topic.id}>
            {topic.name}
          </Box>
          {
            returningAt &&
            <Button style="subdued" size="small" css={{borderColor: brand, pointerEvents: "none", marginRight: "20px"}}>
              <CalendarIcon />
              Coming up at {' '}
              {
                DateTime.fromISO(returningAt).toFormat(`hh:mm a LLL dd`)
              }
            </Button>
          }
        </Box>
        <Box css={{
          display: "flex",
          alignItems: "center"
        }}>
          {isHome &&
            <Box className='community-tag'>{topic.community.name}</Box>
          }
          <IconButton
            title="Share"
            onClick={async () => {
            if (navigator.clipboard) {
              const locationWithoutHash = window.location.toString().split('#')[0];
              const shareLink = `${locationWithoutHash}#ts-${topic.id}`;
              await navigator.clipboard.writeText(shareLink);
              onLinkCopied();
              track('Topic Shared', {
                'topicId': topic.id,
                'link': shareLink
              });
            }
          }}>
            <Share1Icon />
          </IconButton> 
          {
            topic.creator.firebaseUID === fbUser?.uid &&
            <IconButton
              onClick={() => setConfirmationModalOpen(true)}
              title="Delete">
              <CrossCircledIcon />
            </IconButton>
          }
        </Box>
      </Box>
      <Box css={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}>
        {topic.tags.map((tag) => tag.length > 0 && <Box css={{
          margin: "0 5px 5px 0",
          borderRadius: "8px",
          fontWeight: "600",
          height: "24px",
          padding: "3px 6px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          background: white,
          color: body,
          fontSize: "12px",
        }} key={`${topic.id}_${tag}`}>#{tag}</Box>)}
      </Box>
      <Box css={{
        margin: "10px 0",
        minHeight: 400,
        height: 'fit-content',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <KlatchGrid klatchesFrag={topic.klatches} sessionsFrag={topic} topicId={topic.id} />
      </Box>
      <ConfirmationModal
        visible={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        title={"Delete topic"}
        description={"Are you sure?"}
        onConfirm={() => {
          deleteTopic({
            variables: {
              deleteTopicId: topic.id
            }
          });
          setConfirmationModalOpen(false);
        }}
      />
    </Box>
  );
};
