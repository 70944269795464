import { gql } from "@apollo/client";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { communityAtom, homeAtom, popularPublicTopicsAtom, topicAtom } from "../../../app/atoms";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../styled-components/Accordion";
import Box from "../../../styled-components/Box";
import { useGetCommunityTopicsLazyQuery } from "../../TopicsContent/__generated__/TopicsContent.gql.generated";

gql`
  fragment TopicsNavigatorFragment_topic on Topic {
    id
    interestedPeopleCount
    name
    description
    tags
}`

export default function TopicsNavigator() {
  const [community,] = useAtom(communityAtom);
  const [isHome,] = useAtom(homeAtom);
  const [popularPublicTopics,] = useAtom(popularPublicTopicsAtom);
  const [currentTopic, setCurrentTopic] = useAtom(topicAtom);
  const [getTopics, { data }] = useGetCommunityTopicsLazyQuery({
    variables: {
      communityId: community?.id || ""
    }
  });
  const topicsList = (isHome && popularPublicTopics) || (data?.topics || []);

  useEffect(() => {
    if (!community) return;
    getTopics({
      variables: { communityId: community.id },
    });
  }, [community]);

  useEffect(() => {
    if (topicsList.length <= 0) return;
    setCurrentTopic(topicsList[0]);
  }, [topicsList]);

  if (!topicsList) return null;

  return (
    <Accordion type="single" defaultValue={currentTopic?.id || undefined} value={currentTopic?.id} collapsible>
      {
        topicsList.map((topic: any) => (

          <AccordionItem key={topic.id} value={topic.id}>
            <Box as="a" href={`#ts-${topic.id}`} onClick={() => setCurrentTopic(topic)} css={{ textDecoration: "none" }}>
              <AccordionTrigger >
                {topic.name}
              </AccordionTrigger>
            </Box>
            <AccordionContent>
              <Box>
                {topic.description}
              </Box>
            </AccordionContent>
          </AccordionItem>
        ))
      }
    </Accordion >
  );
};
