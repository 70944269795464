import Box from "../../styled-components/Box";
import newKlatch from '../../assets/images/CreateKlatch.png';
import Button from "../../styled-components/Button";
import { useState } from "react";
import KlatchModal from "../Modals/KlatchModal.gql";
import { KlatchModalFragment_KlatchFragment } from "../Modals/__generated__/KlatchModal.gql.generated";
import { useJoinKlatchMutation } from "./__generated__/KlatchCircle.gql.generated";
import { useLeaveKlatchMutation } from "../NetworkingSection/__generated__/NetworkingSection.gql.generated";
import { JoinKlatchResponse } from "../../types/production.graphql";
import useAudioContext from "../../audio/useAudioContext";
import { useAtom } from "jotai";
import { klatchAtom } from "../../app/atoms";

type NewKlatchPlaceholderProps = {
  topicId: string
}

export default function NewKlatchPlaceholder({ topicId }: NewKlatchPlaceholderProps) {
  const [klatchModalVisible, setKlatchModalVisible] = useState(false);
  const [activeKlatch, setActiveKlatch] = useAtom(klatchAtom);
  const {connectKlatchAudio, klatchRoom } = useAudioContext();

  const [joinKlatch] = useJoinKlatchMutation();
  const [leaveKlatch] = useLeaveKlatchMutation({
    refetchQueries: ["GetCommunityTopics", "HomePage"]
  });

  async function _leaveKlatch() {
    if (klatchRoom) {
      klatchRoom.disconnect();
    }
    if (!activeKlatch) return;
    await leaveKlatch({
      variables: {
        input: {
          id: activeKlatch.id,
          topicId: activeKlatch.topicId
        }
      }
    });
    setActiveKlatch(undefined);
  }

  async function joinKlatchRoom(data: KlatchModalFragment_KlatchFragment) {
    await _leaveKlatch();
    const res = await joinKlatch({
      variables: {
        klatchId: data.id
      }
    });
    setActiveKlatch(res?.data?.joinKlatch as JoinKlatchResponse);
    if (!res?.data?.joinKlatch) return;
    await connectKlatchAudio(data.id, res.data.joinKlatch.accessToken);
  }

  return (
    <Box css={{
      border: "1px solid #e0e0e0",
      background: "white",
      borderRadius: "5px",
      width: "100%",
      height: "290px",
      position: "relative",
      animationName: "fadeIn",
      animationDuration: "0.2s",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }}>
      <Box as="img" src={newKlatch} css={{ width: "170px", margin: "auto" }} />
      <Box css={{
        margin: "0 8px 20px 8px",
        height: "40px"
      }}>
        <Button
          fullWidth
          style="outlined"
          onClick={() => setKlatchModalVisible(true)}>
          Start a klatch now
        </Button>
      </Box>
      <KlatchModal
        visible={klatchModalVisible}
        onClose={(data: KlatchModalFragment_KlatchFragment) => {
          if (data) {
            joinKlatchRoom(data);
          }
          setKlatchModalVisible(false);
        }}
        klatch={undefined}
        topicId={topicId} />
    </Box>
  );
};
