import { useState } from 'react';
import { PlusIcon } from '@radix-ui/react-icons';
import Button from '../../styled-components/Button';
import CommunitySelect from './CommunitySelect/CommunitySelect.gql';
import TopicModal from '../Modals/TopicModal.gql';
import Box from '../../styled-components/Box';
import { brand, divider } from '../../static/styles/colorConstants';
import TopicsNavigator from './TopicsNavigator/TopicsNavigator.gql';
import { homeAtom, communityAtom } from '../../app/atoms';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';


export default function NavigationSidebar() {

  const [topicModalOpen, setTopicModalOpen] = useState(false);
  const [isHome, setIsHome] = useAtom(homeAtom);
  const [, setCommunity] = useAtom(communityAtom);
  const history = useHistory();

  return (
    <>
      <Box css={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}>
        <Box css={{
          padding: "8px 18px",
          fontSize: "24px",
          lineHeight: "29px",
          fontWeight: 800,
          color: brand,
          cursor: "pointer",
          borderBottom: `1px solid ${divider}`,
        }}
          onClick={(value: any) => {
            if (isHome) return;
            history.push('/');
            setIsHome(true);
            setCommunity(undefined);
          }}>
          Klatchup
        </Box>

        <Box css={{ margin: '20px 0' }}>
          <CommunitySelect />
        </Box>
        <Box css={{
          flex: 1,
          maxHeight: "60vh",
          overflowY: "auto",
          overflowX: "hidden",
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'none'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: divider,
            borderRadius: '5px',
            height: '5px',
          }
        }}>
          <TopicsNavigator />
        </Box>
        {
          !isHome &&
          <Button
            fullWidth
            onClick={() => setTopicModalOpen(true)}>
            <PlusIcon />
            Start a topic
          </Button>
        }
      </Box>
      <TopicModal
        visible={topicModalOpen}
        onClose={() => setTopicModalOpen(false)}
        topic={undefined} />
    </>
  );
};
