import * as Types from '../../../../types/production.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommunitySelectFragment_CommunityFragment = { __typename?: 'Community', id: string, name: string | null, realNameRequired: boolean, createdAt: any, communityUserCount: number | null, description: string | null, viewerFollows: boolean | null };

export type JoinCurrentCommunityMutationVariables = Types.Exact<{
  input: Types.CommunityFollowInput;
}>;


export type JoinCurrentCommunityMutation = { __typename?: 'Mutation', followPublicCommunity: { __typename?: 'CommunityUser', createdAt: any, namePreference: Types.NamePreference } | null };

export const CommunitySelectFragment_CommunityFragmentDoc = gql`
    fragment CommunitySelectFragment_community on Community {
  id
  name
  realNameRequired
  createdAt
  communityUserCount
  description
  viewerFollows
}
    `;
export const JoinCurrentCommunityDocument = gql`
    mutation JoinCurrentCommunity($input: CommunityFollowInput!) {
  followPublicCommunity(input: $input) {
    createdAt
    namePreference
  }
}
    `;
export type JoinCurrentCommunityMutationFn = Apollo.MutationFunction<JoinCurrentCommunityMutation, JoinCurrentCommunityMutationVariables>;

/**
 * __useJoinCurrentCommunityMutation__
 *
 * To run a mutation, you first call `useJoinCurrentCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCurrentCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCurrentCommunityMutation, { data, loading, error }] = useJoinCurrentCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinCurrentCommunityMutation(baseOptions?: Apollo.MutationHookOptions<JoinCurrentCommunityMutation, JoinCurrentCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinCurrentCommunityMutation, JoinCurrentCommunityMutationVariables>(JoinCurrentCommunityDocument, options);
      }
export type JoinCurrentCommunityMutationHookResult = ReturnType<typeof useJoinCurrentCommunityMutation>;
export type JoinCurrentCommunityMutationResult = Apollo.MutationResult<JoinCurrentCommunityMutation>;
export type JoinCurrentCommunityMutationOptions = Apollo.BaseMutationOptions<JoinCurrentCommunityMutation, JoinCurrentCommunityMutationVariables>;