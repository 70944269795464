import { atom } from 'jotai';
import { CommunitySelectFragment_CommunityFragment } from '../components/NavigationSidebar/CommunitySelect/__generated__/CommunitySelect.gql.generated';
import { TopicsNavigatorFragment_TopicFragment } from '../components/NavigationSidebar/TopicsNavigator/__generated__/TopicsNavigator.gql.generated';
import { TopicSectionFragment_TopicFragment } from '../components/TopicSection/__generated__/TopicSection.gql.generated';
import { JoinKlatchResponse } from '../types/production.graphql';

export const communityAtom = atom<CommunitySelectFragment_CommunityFragment | undefined>(undefined);
export const topicAtom = atom<TopicsNavigatorFragment_TopicFragment | undefined>(undefined);
export const homeAtom = atom<boolean>(false);
export const popularPublicTopicsAtom = atom<TopicSectionFragment_TopicFragment[] | undefined>(undefined);
export const klatchAtom = atom<JoinKlatchResponse | undefined>(undefined);
