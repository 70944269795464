import { createStitches } from "@stitches/react";

const { styled } = createStitches({
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
  },
});

export {
  styled
};
