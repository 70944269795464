import { styled } from "@stitches/react";
import { body, brand, divider } from "../static/styles/colorConstants";

const TextArea = styled('textarea', {
  all: 'unset',
  width: '100%',
  flex: '1',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '5px',
  fontSize: 15,
  color: body,
  wordBreak: "break-all",
  boxShadow: `0 0 0 1px ${divider}`,
  height: 100,

  '&:focus': { boxShadow: `0 0 0 1px ${brand}` },
});

export { TextArea };
