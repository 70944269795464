import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import Box from "../../../styled-components/Box";
import { body, brand, divider } from "../../../static/styles/colorConstants";
import { Toast, ToastDescription, ToastTitle, ToastViewport, ToastAction } from "../../../styled-components/Toast";
import SessionRow from "./SessionRow.gql";
import { SessionsContainerFragment_TopicFragment, useComeBackLaterMutation } from "./__generated__/SessionsContainer.gql.generated";
import NewSessionSection from "./NewSessionSection";
import LoadingDots from "../../../styled-components/Loading";
import { IconButton } from "../../../styled-components/IconButton";
import communityHeart from '../../../assets/images/CommunityHeart.png';
import { track } from "../../../app/tracking";
import useAuthContext from "../../../auth/useAuthContext";
import Button from "../../../styled-components/Button";
import { atcb_init, atcb_action } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css';
import { DateTime } from "luxon";

gql`
  mutation ComeBackLater($input: TopicJoinInput!) {
    joinPublicTopic(input: $input) {
      returningAt
      updatedAt
      createdAt
    }
  }
`;

gql`
  fragment SessionsContainerFragment_topic on Topic {
    name
    upcomingSessionParticipants {
      returningAt
      user {
        id
        username
        bio
        icebreaker
        photoUrl
      }
    }
  }
`;

type SessionsContainerProps = {
  topicId: string;
  sessionsFrag: SessionsContainerFragment_TopicFragment;
}

export default function SessionsContainer({ topicId, sessionsFrag }: SessionsContainerProps) {
  const { fbUser } = useAuthContext();
  const [showToast, setShowToast] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [comeBackLaterMutation] = useComeBackLaterMutation();
  const [aggregatedSessions, setAggregatedSessions] = useState<{ time: string, users: any[] }[]>();

  useEffect(() => {
    atcb_init();
  }, []);

  useEffect(() => {
    loadSessionsInfo();
  }, [sessionsFrag]);

  const saveTime = async (existingDateTime: string) => {
    await comeBackLaterMutation({
      variables: {
        input: {
          id: topicId,
          returningAt: existingDateTime,
          sendNotifications: true
        }
      },
      refetchQueries: ["GetCommunityTopics", "HomePage"]
    });
    setShowToast(true);
  };

  const onAddToCalendar = () => {
    if (!selectedTime) return;
    const date = DateTime.fromISO(selectedTime).toFormat('yyyy-LL-dd');
    const time = DateTime.fromISO(selectedTime).toFormat('HH:mm');
    const endTime = DateTime.fromISO(selectedTime).plus({ minutes: 30 }).toFormat('HH:mm');
    const locationWithoutHash = window.location.toString().split('#')[0];
    const shareLink = `${locationWithoutHash}#ts-${topicId}`;
    atcb_action({
      name: `Klatchup Meet Up: ${sessionsFrag.name}`,
      location: shareLink,
      startDate: date,
      endDate: date,
      startTime: time,
      endTime,
      options: ['Apple', 'Google', 'iCal'],
      iCalFileName: "Klatchup Meet Up",
      timeZone: 'currentBrowser'
    });
  };

  const loadSessionsInfo = () => {
    const sessionsMap: { [key: string]: any[] } = {};
    sessionsFrag?.upcomingSessionParticipants?.map((participant) => {
      if (!sessionsMap[participant.returningAt]) {
        sessionsMap[participant.returningAt] = [];
      }
      sessionsMap[participant.returningAt].push(participant.user);
    });
    const keys = Object.keys(sessionsMap);
    const values = Object.values(sessionsMap);
    const aggregated = keys.map((k, i) => {
      return {
        time: k,
        users: values[i]
      };
    });
    setAggregatedSessions(aggregated);
  };

  if (!aggregatedSessions) return <LoadingDots />;

  return (
    <Box css={{
      border: "1px solid #e0e0e0",
      background: "white",
      borderRadius: "5px",
      width: "536px",
      height: "290px",
      position: "relative",
      animationName: "fadeIn",
      animationDuration: "0.2s",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end"
    }}>
      {
        aggregatedSessions?.length === 0 && (
          <Box css={{ color: body, margin: "20px", flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            Don’t see anyone yet? <br></br><br></br>Bring the community together by scheduling a meet up! <br></br>
            <Box as="img" src={communityHeart} css={{ width: "150px", margin: "auto" }} />
          </Box>
        )
      }
      {
        aggregatedSessions?.length > 0 &&
        <Box css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "20px",
          overflowY: "auto",
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'none'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: divider,
            borderRadius: '5px',
            height: '5px',
          }
        }}>
          <Box css={{ height: "30px", color: brand, fontSize: "18px", fontWeight: 600 }}>
            Meet Ups
          </Box>
          <Box css={{ height: "20px", color: body, fontSize: "14px", fontWeight: 400 }}>
            Sign up for one or make your own
          </Box>
          {
            aggregatedSessions.map((session) => (
              <SessionRow key={session.time} time={session.time} users={session.users} onSessionJoinClicked={() => {
                track('Existing Session Joined', {
                  'topicId': topicId,
                  'time': session.time,
                  'signupCount': session.users.length + 1
                });
                setSelectedTime(session.time);
                saveTime(session.time);
              }} />
            ))
          }
        </Box>
      }
      <NewSessionSection onSessionTimeSelected={(time) => {
        track('New Meet Up Scheduled', {
          'time': time,
          'topicId': topicId,
          'email': fbUser?.email,
        })
        setSelectedTime(time);
        saveTime(time);
      }} />
      <Toast open={showToast} duration={20000} onOpenChange={(open) => !open && setShowToast(false)}>
        <ToastTitle>RSVP Saved</ToastTitle>
        <ToastDescription>
          Don't forget to come back!
        </ToastDescription>
        <ToastAction asChild altText="Add to Calendar">
          <Button onClick={() => onAddToCalendar()} css={{ unset: 'all !important' }} style="outlined" size="small">
            Add to Calendar
          </Button>
        </ToastAction>
      </Toast>
      <ToastViewport />
    </Box>
  );
};
