import * as Types from '../../../../types/production.graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSearchResultsQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
}>;


export type GetSearchResultsQuery = { __typename?: 'Query', searchResults: Array<{ __typename?: 'Community', id: string, realNameRequired: boolean, createdAt: any, communityUserCount: number | null, viewerFollows: boolean | null, communityName: string | null, communityDescription: string | null } | { __typename?: 'Topic', id: string, interestedPeopleCount: number, tags: Array<string>, topicName: string, topicDescription: string, community: { __typename?: 'Community', id: string, realNameRequired: boolean, createdAt: any, communityUserCount: number | null, viewerFollows: boolean | null, communityName: string | null, communityDescription: string | null } }> };

export type SearchTopicsFragment_TopicFragment = { __typename?: 'Topic', id: string, interestedPeopleCount: number, tags: Array<string>, topicName: string, topicDescription: string };

export type SearchCommunityFragment_CommunityFragment = { __typename?: 'Community', id: string, realNameRequired: boolean, createdAt: any, communityUserCount: number | null, viewerFollows: boolean | null, communityName: string | null, communityDescription: string | null };

export const SearchTopicsFragment_TopicFragmentDoc = gql`
    fragment SearchTopicsFragment_topic on Topic {
  id
  interestedPeopleCount
  topicName: name
  topicDescription: description
  tags
}
    `;
export const SearchCommunityFragment_CommunityFragmentDoc = gql`
    fragment SearchCommunityFragment_community on Community {
  id
  communityName: name
  realNameRequired
  createdAt
  communityUserCount
  communityDescription: description
  viewerFollows
}
    `;
export const GetSearchResultsDocument = gql`
    query GetSearchResults($query: String!) {
  searchResults: search(query: $query) {
    ... on Topic {
      ...SearchTopicsFragment_topic
      community {
        ... on Community {
          ...SearchCommunityFragment_community
        }
      }
    }
    ... on Community {
      ...SearchCommunityFragment_community
    }
  }
}
    ${SearchTopicsFragment_TopicFragmentDoc}
${SearchCommunityFragment_CommunityFragmentDoc}`;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(GetSearchResultsDocument, options);
      }
export function useGetSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(GetSearchResultsDocument, options);
        }
export type GetSearchResultsQueryHookResult = ReturnType<typeof useGetSearchResultsQuery>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSearchResultsLazyQuery>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<GetSearchResultsQuery, GetSearchResultsQueryVariables>;