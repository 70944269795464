import { styled } from "@stitches/react";
import { body, brand, divider } from "../static/styles/colorConstants";

const Input = styled('input', {
  all: 'unset',
  width: '100%',
  flex: '1',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '0 10px',
  fontSize: 15,
  lineHeight: 1,
  color: body,
  boxShadow: `0 0 0 1px ${divider}`,
  height: 35,

  '&:focus': { boxShadow: `0 0 0 1px ${brand}` },
  variants: {
    disabled: {
      true: {
        backgroundColor: divider,
        pointerEvents: "none",
      }
    }
  }
});

export { Input };
