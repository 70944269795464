// Theme colors (TODO: turn it into a real theme)
export const brand = "#EB6C25";
export const brandHover = "#f57a36";
export const subduedHover = "#F6F7FA";
export const heading = "#121212";
export const body = "#5E6D84";
export const icon = "#A4A9B3";
export const divider = "#d3d4db";
export const background = "#F6F7FA";
export const white = "#FFFFFF";
export const errorRed = "#F62E2E";
export const errorRedHover = "#f73939";
export const success = "#48c76e";
